'use strict';

angular.module('forms.modules.avowal',
    [
        'forms.helper',
        'rol.form.validation',
        'rol.translation'
    ]
)

    .directive('avowal', function(grabDefinition, Translator, $http, gettextCatalog) {
        return {
            restrict: 'E',
            scope: {
                'actions': '=',
                'parameters': '=',
                'info': '=',
                'data': '='
            },
            templateUrl: 'modules/signalization/avowal.tpl.html',
            link: function (scope) {

                /*****************************************
                 * Validation
                 *****************************************/
                scope.hasValidationErrors = function() {
                    return scope.avowalForm.$submitted && !scope.avowalForm.$valid;
                };

                scope.getValidationClass = function(field) {
                    if(scope['avowalForm'].$submitted && scope['avowalForm'][field].$invalid) {
                        return 'forms__label--error';
                    }
                    return '';
                };

                /*****************************************
                 * Actions
                 *****************************************/
                scope.actions.validate = function () {
                    scope.avowalForm.$setSubmitted();

                    return (scope.avowalForm.$valid === true);
                };

                /*****************************************
                 * Check if filter contains Location Info
                 *****************************************/
                function checkLocation(filters, filterAmount) {
                    var i;
                    for (i = 1; i <= filterAmount; i++) {
                        var filterItem = filters[i];
                        var location = filterItem['location'];
                        var hasLocation = filterItem.hasOwnProperty('location');

                        if (hasLocation && (location != null && location != '')) {
                            scope.locationId = location;
                            prepareComment();
                        }
                    }
                }

                /*****************************************
                 * Prepare Comment
                 *****************************************/
                function prepareComment() {
                    $http({
                        method: 'GET',
                        url: 'https://country.api.roldev.it/v1/communities'
                    }).then(function successCallback(response) {

                        var communitiesArray = response.data.data;
                        var locationObject = communitiesArray.filter(checkIfChosenLocation)[0];

                        if (locationObject) {
                            var keytosearch = 'value_' + gettextCatalog.getCurrentLanguage();
                            var currentlocation = locationObject[keytosearch];

                            if (currentlocation !== '' && currentlocation !== undefined) {
                                var place = Translator.translate('forms.customer_information.city');
                                scope.data.signalization.comment = place + ': ' + currentlocation;
                            }
                        }

                    }, function errorCallback(response) {
                        console.log('api error, no locations found');
                        return false;
                    });
                }

                /*****************************************
                 * Check if Chosen location is
                 * present In Locations Array
                 *****************************************/
                function checkIfChosenLocation(locationobject) {
                    if (locationobject.key == scope.locationId) {
                        return locationobject;
                    }
                }

                /*****************************************
                 * Init
                 *****************************************/
                function init() {

                    // Description InfoBox
                    if (typeof scope.info.signalization == 'object') {
                        scope.description = Translator.translateVariable('description', scope.info.signalization);
                    }

                    // Check if any filter is set
                    if (typeof scope.data.forms.filters !== 'undefined') {
                        var filters = scope.data.forms.filters;
                        var filterAmount = Object.keys(filters).length;

                        if (filterAmount > 0) {
                            if (typeof scope.data.signalization === 'undefined') {
                                scope.data.signalization = {};
                                scope.data.signalization.comment = '';

                                checkLocation(filters, filterAmount);
                            }
                        }
                    }
                }

                init();
            }
        };
    })

    .service('AvowalSummaryService', function(Translator, extractNestedValue) {
        return {
            getSummary: function(info, data, parameters) {
                return [
                    {
                        'section': Translator.translate('forms.avowal.comment'),
                        'data': [
                            {
                                'identifier': 'signalization.comment',
                                'type': 'value-only'
                            }
                        ]
                    }
                ];
            }
        };
    });