'use strict';

angular.module('forms.modules.forms.bundle-items',
    [
        'rol.form',
        'rol.translation'
    ]
)

    .directive('bundleItems', function() {
        return {
            restrict: 'E',
            scope: {
                'definition': '=',
                'data': '=',
                'form': '=',
                'identifierPrefix': '='
            },
            templateUrl: 'modules/forms/bundle-items/bundle-items.tpl.html'
        };
    });