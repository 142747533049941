'use strict';

angular.module('forms.price-summary',
    [
        'forms.helper',
        'rol.form.validation',
        'rol.translation',
        'rol.form.dependency',
        'forms.customer-service'
    ]
)

    .directive('priceSummary', function($rootScope, PriceSummaryService, Translator, DependenciesValidator,
                                        grabDefinition, CustomerService) {
        return {
            restrict: 'E',
            scope: {
                'info': '=',
                'data': '='
            },
            templateUrl: 'shared/price-summary.tpl.html',
            link: function(scope) {
                scope.priceSummary = {
                    bundles: [],
                    summary: {
                        "with_vat": {
                            "one_time": 0,
                            "monthly": 0,
                            "trimestral": 0,
                            "yearly": 0
                        },
                        "without_vat": {
                            "one_time": 0,
                            "monthly": 0,
                            "trimestral": 0,
                            "yearly": 0
                        },
                        "vat": {
                            "one_time": 0,
                            "monthly": 0,
                            "trimestral": 0,
                            "yearly": 0
                        },
                        "vat_percentage": 0
                    }
                };

                scope.loading = false;

                function updateSummary() {

                    if (scope.data.forms.object_type === 'virtual-bundle'
                        && typeof scope.data.forms.items === 'undefined') {
                        return;
                    }

                    scope.loading = true;
                    scope.definition = grabDefinition(scope.info, scope.data);
                    scope.customerId = getCustomerId();

                    var selectedBundles = getSelectedBundles();

                    PriceSummaryService.post({
                        customer_id: scope.customerId,
                        bundles: selectedBundles
                    }, function(data) {
                        angular.copy(data, scope.priceSummary);
                        scope.loading = false;
                        $rootScope.$broadcast('bundles-updated');
                    }, function(err) {
                        console.log(err);
                        scope.loading = false;
                        //@todo handle errors
                    });
                }

                function getCustomerId() {
                    return CustomerService.getCustomerId(scope.data);
                }

                function getSelectedBundles() {
                    var selectedBundles = [];
                    var formData = scope.data.forms;

                    if (formData['object_type'] === 'bundle') {
                        selectedBundles.push({
                            id: formData['object_id'],
                            quantity: 1
                        });

                    } else if (formData['object_type'] === 'virtual-bundle') {
                        selectedBundles = {};
                        scope.definition.virtual_bundle_items.forEach(function(virtualBundleItem) {
                            var data = formData.items[virtualBundleItem.id];

                            if (typeof data !== 'undefined' && data['bundle'] !== null
                                && hasValidDependencies(virtualBundleItem)) {
                                selectedBundles[virtualBundleItem.position] = {
                                    id: data['bundle']['object_id'],
                                    quantity: 1
                                };
                            }
                        });
                    }

                    return selectedBundles;
                }

                scope.$on('update-bundles', function(event) {
                    updateSummary();
                });

                scope.$on('wizard.started', function(event) {
                    updateSummary();
                });

                function hasValidDependencies(object) {
                    return DependenciesValidator.validate(object, scope.data.forms);
                }

                scope.getBundleName = function(bundle) {
                    return Translator.translateVariable('name', bundle);
                };

                scope.isCommercial = function() {
                    return CustomerService.isCommercial(scope.data, scope.definition);
                };
            }
        };
    })

    .directive('setClassWhenAtTop', function ($window, $timeout) {

        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var topClass = attrs.setClassWhenAtTop;
                var marginTop = parseInt($window.getComputedStyle(element[0]).marginTop);
                var offsetTop = element[0].offsetTop;

                var fixedToTop = true;

                function outerHeight(element) {
                    var height = element[0].offsetHeight;
                    var style = getComputedStyle(element[0]);

                    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
                    return height;
                }

                function scrollTop() {
                    return (window.pageYOffset !== undefined)
                            ? window.pageYOffset
                            : (document.documentElement
                        || document.body.parentNode
                        || document.body).scrollTop;
                }

                function checkScroll() {
                    if (scrollTop() >= offsetTop + marginTop) {
                        element.addClass(topClass);
                    } else {
                        element.removeClass(topClass);
                    }
                }

                function checkHeight() {
                    if (outerHeight(element) > document.body.clientHeight) {
                        fixedToTop = false;
                        element.removeClass(topClass);
                    } else {
                        fixedToTop = true;
                        checkScroll();
                    }
                }

                function needsFix() {
                    var modules = angular.element(document.getElementById('wizard_modules'));
                    return outerHeight(element) < outerHeight(modules);
                }

                scope.$on('bundles-updated', function() {
                    $timeout(function() {
                        checkHeight();
                    }, 0);
                });

                $window.onresize =  function (e) {
                    checkHeight();
                };

                $window.onscroll = function (e) {
                    if (fixedToTop && needsFix()) {
                        checkScroll();
                    }
                };
            }
        };
    })

    .directive('priceSummaryPrivate', function() {
        return {
            restrict: 'E',
            scope: {
                'priceSummary': '=',
                'loading': '=',
                'getBundleName': '='
            },
            templateUrl: 'shared/price-summary-private.tpl.html',
            link: function (scope) {
            }
        }
    })

    .directive('priceSummaryCommercial', function() {
        return {
            restrict: 'E',
            scope: {
                'priceSummary': '=',
                'loading': '=',
                'getBundleName': '='
            },
            templateUrl: 'shared/price-summary-commercial.tpl.html',
            link: function (scope) {
            }
        }
    })


    .service('PriceSummaryService', function (env, ApiService, $http) {

        function post(data, successCallback, failureCallback) {
            $http({
                method: 'POST',
                url: env['apiUrl'] + 'price-summary',
                data: data
            }).then(ApiService.successMiddleware(successCallback), ApiService.failureMiddleware(failureCallback));
        }

        return {
            post: post
        };
    });