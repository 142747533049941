'use strict';

angular.module('forms',
    [
        'user',
        'forms.config',
        'forms.env',
        'forms.routes',
        'templates',

        'forms.container'
    ]
);