'use strict';

angular.module('forms.notifications.save',
    [
        'forms.env',
        'rol.translation'
    ]
)

    .directive('save', function() {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: 'notifications/save/save.tpl.html'
        };
    });