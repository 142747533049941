'use strict';

angular.module('forms.customer-service',
    [
    ]
)

    .service('CustomerService', function () {

        function getCustomerId(data) {
            var customerId = null;

            if (data.customer_information !== undefined && data.customer_information !== null) {
                customerId = data.customer_information.customer_id;
            }

            return customerId;
        }

        function isCommercial(data, definition) {
            if (data.customer_information !== undefined && data.customer_information !== null) {
                return data.customer_information.entity_type !== 'PRI';
            } else if (typeof definition !== 'undefined') {
                var customerGroups = definition.customer_groups;

                if (typeof customerGroups !== 'undefined') {
                    customerGroups.forEach(function(customerGroup) {
                        if (customerGroup.code === 'NPO' || customerGroup.code === 'GES') {
                            return true;
                        }
                    });
                }
            }

            return false;
        }

        return {
            getCustomerId: getCustomerId,
            isCommercial: isCommercial
        };
    });