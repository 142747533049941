'use strict';

angular.module('forms.modules.summary',
    [
        'forms.helper',
        'rol.form',
        'rol.translation'
    ]
)

    .directive('summary', function($injector, $sce, grabDefinition, grabAgb, extractNestedValue, Translator) {
        return {
            restrict: 'E',
            scope: {
                'actions': '=',
                'parameters': '=',
                'info': '=',
                'data': '=',

                'call': '=',
                'moduleCallable': '=',
                'moduleParameters': '='
            },
            templateUrl: 'modules/summary/summary.tpl.html',

            link: function(scope) {

                /*****************************************
                 * User instruction
                 *****************************************/
                scope.showUserInstruction = function() {
                    return typeof scope.parameters.userInstruction === "boolean" && scope.parameters.userInstruction === true;
                };

                /*****************************************
                 * AGB
                 *****************************************/
                function prepareAgb() {
                    scope.agb = null;

                    if(scope.info['type'] !== 'contract') {
                        return;
                    }

                    var definition = grabDefinition(scope.info, scope.data);
                    var agb = grabAgb(definition, scope.info, scope.data);
                    
                    if(agb !== null) {
                        scope.agb = $sce.trustAsHtml(agb);
                        
                        if(typeof scope.data['summary']['agb_accepted'] === 'undefined') {
                            scope.data['summary']['agb_accepted'] = false;
                        }
                    }
                }

                scope.displayAgb = function() {
                    return scope.info['type'] === 'contract' && scope.agb !== null;
                };

                scope.acceptedAgb = function() {
                    if(scope.displayAgb()) {
                        return typeof scope.data['summary']['agb_accepted'] !== 'undefined' && scope.data['summary']['agb_accepted'] === true;
                    } else {
                        return true;
                    }
                };

                scope.showAgbMessage = function() {
                    if(scope.formSummary.$submitted !== true) {
                        return false;
                    }

                    return ! scope.acceptedAgb();
                };


                /*****************************************
                 * PDF
                 *****************************************/
                function preparePdf() {

                    // Display 
                    scope.pdf = false;
                    
                    if(scope.info['type'] === 'sepa') {
                        scope.pdf = true;
                    } else if(scope.info['type'] === 'contract') {
                        var definition = grabDefinition(scope.info, scope.data);
                        
                        scope.pdf = definition['pdf_template_id'] !== null;
                    }

                    // Default Email
                    if(scope.displayPdf()) {
                        if(typeof scope.data['summary']['pdf_email'] === 'undefined' && typeof scope.data['customer_information'] === 'object' && typeof scope.data['customer_information']['email'] === 'string') {
                            scope.data['summary']['pdf_email'] = scope.data['customer_information']['email'];
                        }
                    }
                }
                
                scope.displayPdf = function() {
                    var hidePdfMail = typeof scope.parameters.hidePdfMail === "boolean" && scope.parameters.hidePdfMail === true;
                    if (hidePdfMail === false) {
                        return scope.pdf;
                    } else {
                        return false;
                    }
                };

                /*****************************************
                 * Validation Errors
                 *****************************************/
                function cleanValidationErrors() {
                    scope.parameters.validation_errors.splice(0, scope.parameters.validation_errors.length);
                }

                scope.showValidationMessage = function() {
                    return scope.parameters.validation_errors.length > 0
                        || (scope.formSummary.$submitted && !scope.formSummary.$valid);
                };

                scope.hasValidationErrors = function(identifiers) {
                    if (typeof identifiers === 'undefined') {
                        return false;
                    }

                    if(typeof identifiers === 'string') {
                        identifiers = [identifiers];
                    }

                    for(var i = 0; i < identifiers.length; i++) {
                        var identifier = identifiers[i];

                        for(var j = 0; j < scope.parameters.validation_errors.length; j++) {
                            var validationError = scope.parameters.validation_errors[j];

                            if(validationError.identifier === identifier) {
                                return true;
                            }
                        }
                    }

                    return false;
                };

                /*****************************************
                 * Summary
                 *****************************************/
                function prepareSummary() {
                    var summary = [];
                    var i, j;

                    // Helper
                    function hyphenToCamelCase(string) {
                        return string.replace(/(?:^|-)([a-z])/g, function(g) {
                            return (g[1] || g[0]).toUpperCase();
                        });
                    }

                    // Get the summary of every module defined in the parameters
                    for (i = 0; i < scope.parameters.modules.length; i++) {
                        var service = $injector.get(
                            hyphenToCamelCase(scope.parameters.modules[i]) + 'SummaryService'
                        );

                        var moduleParameters = scope.moduleParameters(scope.parameters.modules[i]);
                        var moduleSummary = service.getSummary(scope.info, scope.data, moduleParameters);

                        for (j = 0; j < moduleSummary.length; j++) {
                            moduleSummary[j].moduleName = scope.parameters.modules[i];
                        }

                        summary = summary.concat(moduleSummary);
                    }

                    // Get values from identifiers + remove empty elements and empty sections
                    for (i = 0; i < summary.length; i++) {

                        if (summary[i].data !== null) {
                            for (j = 0; j < summary[i].data.length; j++) {
                                var item = summary[i].data[j];

                                if (item && item.value == null && (item.type === 'key-value' || item.type == 'value-only')) {
                                    item.value = extractNestedValue(scope.data, item.identifier)
                                }
                                if (item && item.label == null && item.type === 'key-value') {
                                    var gettextId = 'forms.' + item.identifier;
                                    item.label = Translator.translate(gettextId);
                                }

                                if (
                                    !item
                                    || (item.type !== 'separator' && (item.value == null || item.value == ''))
                                ) {
                                    summary[i].data.splice(j, 1);
                                    j--;
                                }
                            }

                            // Remove separators that separate an empty form
                            for (j = 0; j < summary[i].data.length; j++) {
                                var currentItem = summary[i].data[j];

                                if(currentItem.type !== 'separator') {
                                    continue;
                                }

                                // Remove separator if it is the last item
                                if(summary[i].data.length <= (j + 1)) {
                                    summary[i].data.splice(j, 1);
                                    j--;
                                    continue;
                                }

                                // Remove separator if the previous item is a separator or a value-only object
                                if(j > 0) {
                                    var previousItem = summary[i].data[j - 1];

                                    if(previousItem.type === 'separator' || previousItem.type === 'value-only') {
                                        summary[i].data.splice(j, 1);
                                        j--;
                                        continue;
                                    }
                                }

                                // Remove separator if the next item is also a separator or a value-only object
                                var nextItem = summary[i].data[j + 1];

                                if(nextItem.type === 'separator' || nextItem.type === 'value-only') {
                                    summary[i].data.splice(j, 1);
                                    j--;
                                    continue;
                                }
                            }
                        }

                        if (!summary[i].data || summary[i].data.length == 0) {
                            summary.splice(i, 1);
                            i--;
                        }
                    }

                    scope.summary = summary;
                }

                /*****************************************
                 * Actions
                 *****************************************/
                scope.actions.validate = function () {
                    scope.formSummary.$setSubmitted();

                    return (scope.formSummary.$valid === true && scope.acceptedAgb());
                };

                /*****************************************
                 * Init
                 *****************************************/
                function init() {
                    if(typeof scope.data['summary'] !== 'object' || scope.data['summary'] === null) {
                        scope.data['summary'] = {};
                    }
                    
                    prepareSummary();
                    prepareAgb();
                    preparePdf();

                    cleanValidationErrors();
                }

                init();
            }
        };
    })

    .factory('grabAgb', ['Translator', 'DependenciesValidator', function(Translator, DependenciesValidator) {
        return function(definition, info, data) {

            function addAgbTemplate(templates, id) {
                for(var i = 0; i < templates.length; i++) {
                    if(templates[i] === id) {
                        return;
                    }
                }

                templates.push(id);
            }

            function addBundleAgbTemplates(templates, definition) {
                definition['bundle_items'].forEach(function(bundleItem) {

                    if((typeof bundleItem['agb_template_id'] === 'undefined' || bundleItem['agb_template_id'] === null) && (typeof bundleItem['agb_template'] === 'undefined' || bundleItem['agb_template'] === null)) {
                        return;
                    }

                    if (typeof bundleItem['agb_template_id'] !== 'undefined' && bundleItem['agb_template_id'] !== null) {
                        addAgbTemplate(templates, bundleItem['agb_template_id']);
                    } else if (typeof bundleItem['agb_template'] !== 'undefined' && bundleItem['agb_template'] !== null) {
                        if (typeof bundleItem['agb_template'].id === 'undefined' || bundleItem['agb_template'].id === null) {
                            return;
                        }
                        addAgbTemplate(templates, bundleItem['agb_template'].id);
                    }
                });
            }

            function addVirtualBundleAgbTemplates(templates, definition) {
                definition['virtual_bundle_items'].forEach(function(virtualBundleItem) {

                    if(! DependenciesValidator.validate(virtualBundleItem, data['forms'])) {
                        return;
                    }

                    var id = virtualBundleItem['id'];
                    var bundle = data['forms']['items'][id]['bundle'];

                    if(bundle === null) {
                        return;
                    }

                    for(var i = 0; i < virtualBundleItem['items'].length; i++) {
                        var selectedVirtualBundleItem = virtualBundleItem['items'][i]['bundle'];

                        if(selectedVirtualBundleItem['id'] === bundle['object_id']) {
                            addBundleAgbTemplates(templates, selectedVirtualBundleItem);
                        }
                    }

                });
            }

            function collectAgbTemplates(definition) {
                var templates = [];

                if (data['forms']['object_type'] === 'bundle') {
                    addBundleAgbTemplates(templates, definition);
                }

                if (data['forms']['object_type'] === 'virtual-bundle') {
                    addVirtualBundleAgbTemplates(templates, definition);
                }
                return templates;
            }

            function grabAgbHtml(id) {
                var agbTemplateDefinitions = info['agb_templates'];

                if(typeof agbTemplateDefinitions[id] !== 'undefined' && agbTemplateDefinitions[id] !== null) {
                    return Translator.translateVariable('html', agbTemplateDefinitions[id]);
                }

                return '';
            }

            // Unified AGBs (2020)
            var unifiedAgbs = grabAgbHtml('unified_agbs');

            if (unifiedAgbs) {
                return unifiedAgbs;
            }

            // Old method for AGBs (common_header, agbs, common_footer)
            var templates = collectAgbTemplates(definition);

            if(templates.length === 0) {
                return null;
            }

            // Common Header & Common Footer
            var commonHeader = grabAgbHtml('common_header');
            var commonFooter = grabAgbHtml('common_footer');

            // Agb
            var agbs = '';

            templates.forEach(function(id) {
                agbs += grabAgbHtml(id);
            });

            return commonHeader + agbs + commonFooter;
        }
    }]);
