'use strict';

angular.module('forms.information-stack',
    [
        'rol.translation'
    ]
    )

    .factory('InformationStack', function() {

        function InformationStack() {

            var stack = {};

            this.clear = function() {
                stack = {};
            };

            this.insert = function(index, value) {
                stack[index] = value;
            };

            this.remove = function(index) {
                delete stack[index];
            };

            this.get = function(index) {
                while(index >= 0) {

                    if(typeof stack[index] !== 'undefined') {
                        return stack[index];
                    }

                    index--;
                }

                return undefined;
            };
            
        }

        return {
            getInstance: function() {
                return new InformationStack();
            }
        }

    })

    .factory('TitleInformationStack', function(InformationStack, Translator) {

        function TitleInformationStack() {

            var stack = InformationStack.getInstance();

            this.insert = function(index, title_de, title_it, title_en) {
                stack.insert(index, {
                    'title_de': title_de,
                    'title_it': title_it,
                    'title_en': title_en
                })
            };

            this.remove = function(index) {
                stack.remove(index);
            };

            this.get = function(index) {
                var title = stack.get(index);

                if(typeof title === 'undefined') {
                    return '';
                }

                return Translator.translateVariable('prefix', prefix) + ': ' + Translator.translateVariable('title', title);
            };

        }

        return {
            getInstance: function() {
                return new TitleInformationStack();
            }
        }

    });

