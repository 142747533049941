angular.module('rol.form.fields.geo-location',
    [
        'rol.api',
        'rol.bootstrap-modal',
        'rol.form.validation',
        'rol.translation'
    ]
    )

    .constant('geoLocationConfig', {
        'googleMapsApiKey': 'AIzaSyCKS4aAnqladkMRW7g46cblFMR4vKnmbKE'
    })

    .directive('geoLocation', function(geoLocationConfig, $interval, $timeout, Translator) {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                'title': '@?',
                'required': '=?',
                'defaultCoordinates': '=?'
            },
            templateUrl: 'fields/geo-location.tpl.html',

            link: function (scope, element, attr, ngModel) {

                scope.defaultCoordinates = scope.defaultCoordinates ? scope.defaultCoordinates : { lat: 0, lng: 0 };
                scope.mapLoading = true;

                var googleMaps = {
                    map: null,
                    marker: null
                };

                scope.modalAdditionalButtons = [
                    {
                        'title': Translator.translate('rol.form.fields.geo-location.apply'),
                        'onClick': function() {
                            scope.latitude = googleMaps.marker.getPosition().lat();
                            scope.longitude = googleMaps.marker.getPosition().lng();
                            scope.updateModel();
                            scope.onBlur();
                            if (typeof scope.modalClose === 'function') {
                                scope.modalClose();
                            }
                        }
                    }
                ];

                ngModel.$render = function() {

                    var value = ngModel.$viewValue;

                    if (value !== undefined && value !== null && value.trim() !== '') {
                        var coordinates = getCoordinates(value);
                        scope.latitude = parseFloat(coordinates.lat.toString().replace(',', '.')).toFixed(8).replace('.', ',');
                        scope.longitude = parseFloat(coordinates.lng.toString().replace(',', '.')).toFixed(8).replace('.', ',');
                    }
                };

                scope.onBlur = function() {
                    ngModel.$render();
                };

                scope.updateModel = function() {
                    if (
                        scope.latitude === undefined
                        || scope.longitude === undefined
                        || scope.latitude.toString().trim() === ''
                        || scope.longitude.toString().trim() === ''
                    ) {
                        ngModel.$setViewValue(null);
                    } else {
                        ngModel.$setViewValue(scope.latitude + '/' + scope.longitude);
                    }
                };

                scope.clearMap = function() {
                    googleMaps = {
                        map: null,
                        marker: null
                    }
                };

                scope.setLoading = function() {
                    scope.mapLoading = true;
                };

                scope.initMap = function() {

                    if (typeof window.google === 'undefined') {
                        var script = document.createElement('script');
                        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + geoLocationConfig.googleMapsApiKey + '&libraries=places';
                        document.body.appendChild(script);
                    }

                    var promise = $interval(function() {
                        if (typeof window.google !== 'undefined') {
                            scope.mapLoading = false;
                            $interval.cancel(promise);
                            $timeout(function() { // wait for DOM to render (show map)
                                loadMap();
                            });
                        }
                    }, 250);
                };

                scope.$watch(function() {
                    return ngModel.$invalid;
                }, function(invalid) {
                    if (!angular.equals({'required': true}, ngModel.$error)) {
                        scope.geoLocationValidator = !invalid;
                    } else {
                        scope.geoLocationValidator = true;
                    }
                });

                function loadMap() {

                    var mapContainer = element[0].querySelector('.forms__geo-location__map'),
                        mapSearchBox = element[0].querySelector('.forms__geo-location__map__search-box'),
                        centerMarker = element[0].querySelector('.forms__geo-location__map__marker');

                    if (!googleMaps.map) {

                        googleMaps.map = new google.maps.Map(mapContainer, {
                            scrollwheel: true,
                            zoom: 15,
                            disableDefaultUI: true,
                            zoomControl: true,
                            scaleControl: true,
                            mapTypeControl: true,
                            rotateControl: true
                        });

                        googleMaps.marker = new google.maps.Marker({
                            map: googleMaps.map,
                            draggable: true
                        });

                        addSearchBox(mapSearchBox);
                        addCenterMarker(centerMarker);
                    }

                    googleMaps.map.setZoom(15);

                    googleMaps.marker.setPosition(getCoordinates(ngModel.$modelValue) || scope.defaultCoordinates);
                    googleMaps.map.setCenter(googleMaps.marker.getPosition());

                    // Add the correct class to the pac-container, which is created on the bottom of the body
                    google.maps.event.addListenerOnce(googleMaps.map, 'tilesloaded', function() {
                        var pacContainers = document.getElementsByClassName('pac-container');
                        for (var i = 0; i < pacContainers.length; i++) {
                            if (!/forms__geo-location__pac-container/.test(pacContainers[i].className)) {
                                pacContainers[i].className += ' '+'forms__geo-location__pac-container';
                            }
                        }
                    });
                }

                function getCoordinates(value) {
                    var coordinates = null;
                    if (typeof value === 'string') {
                        var parts = value.split('/');
                        if (parts.length === 2) {
                            coordinates = {
                                lat: parseFloat(parts[0].replace(',', '.')),
                                lng: parseFloat(parts[1].replace(',', '.'))
                            }
                        }
                    }
                    return coordinates;
                }

                function addCenterMarker(centerMarker) {
                    centerMarker.title = 'Center marker on map';
                    centerMarker.addEventListener('click', function() {
                        googleMaps.marker.setPosition(googleMaps.map.getCenter());
                    });
                    googleMaps.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(centerMarker);
                }

                function addSearchBox(mapSearchBox) {
                    var searchBox = new google.maps.places.SearchBox(mapSearchBox);
                    googleMaps.map.controls[google.maps.ControlPosition.TOP_LEFT].push(mapSearchBox);

                    googleMaps.map.addListener('bounds_changed', function() {
                        searchBox.setBounds(googleMaps.map.getBounds());
                    });

                    searchBox.addListener('places_changed', function() {
                        var places = searchBox.getPlaces();
                        if (places.length) {
                            googleMaps.marker.setPosition(places[0].geometry.location);
                            googleMaps.map.setCenter(googleMaps.marker.getPosition());
                        }
                    });
                }
            }
        }
    })
    .directive('geoLocationValidator', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                'geoLocationValidator': '='
            },
            link: function(scope, element, attr, ngModel) {

                scope.$watch(function() {
                    return scope.geoLocationValidator;
                }, function(valid) {
                    ngModel.$setValidity('valid', valid);
                });
            }
        };
    })
    .directive('coordinates', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attr, ngModel) {

                ngModel.$validators.coordinates = function(modelValue) {

                    if (modelValue === undefined || modelValue === null || modelValue === '') {
                        return true;
                    }
                    if (!/^\-?\d+([,\.]\d+)?$/.test(modelValue)) {
                        return false;
                    }

                    var floatValue = parseFloat(modelValue.toString().replace(',', '.'));

                    var min, max;

                    switch(attr.coordinates) {
                        case 'longitude':
                            min = -180;
                            max = 180;
                            break;
                        case 'latitude':
                            min = -90;
                            max = 90;
                            break;
                        default:
                            return false;
                    }

                    return floatValue >= min && floatValue <= max;
                }
            }
        };
    });
