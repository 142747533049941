'use strict';

angular.module('forms.services.templates',
    [
        'forms.config',
        'forms.env'
    ]
)

    .service('TemplatesService', function (env, $window, $httpParamSerializer) {

        function print(id, data, successCallback, failureCallback) {
            $window.open(env['baseUrl'] + '/pdf/downloadtemplate' + '?' + $httpParamSerializer(data.template), '_blank');
            successCallback();
        }
        
        return {
            print: print
        };
    });