'use strict';

angular.module('forms.routes',
    [
        'ngRoute'
    ]
)

    .config(function($routeProvider, $locationProvider) {
        $locationProvider.html5Mode({
            enabled: false,
            requireBase: false
        });
    });
