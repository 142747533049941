'use strict';

angular.module('forms.services.signalizations',
    [
        'forms.config',
        'forms.env',
        'rol.api'
    ]
    )

    .service('SignalizationsService', function (env, ApiService, $http) {

        function get(id, successCallback, failureCallback) {
            successCallback(null);
        }

        function save(id, data, successCallback, failureCallback) {
            successCallback(null);
        }
        
        function complete(id, data, successCallback, failureCallback) {
            $http({
                method: 'POST',
                url: env['apiUrl'] + 'signalizations',
                data: data
            }).then(ApiService.successMiddleware(successCallback), ApiService.failureMiddleware(failureCallback));
        }
        
        return {
            get: get,
            save: save,
            complete: complete
        };
    });