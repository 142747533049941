'use strict';

angular.module('forms.action-buttons', [
    'rol.translation'
])

    .directive('actionButtons', function() {
        return {
            restrict: 'E',
            scope: {
                actionButtonsManager: '='
            },
            templateUrl: 'shared/action-buttons.tpl.html'
        };
    })

    .factory('ActionButtonsManager', function (Translator) {

        function ActionButtonsManager() {

            var self = this;

            /**
             * States
             */
            var currentModule = null;
            var loading = false;
            var disabled = false;

            self.setCurrentModule = function(module) {
                currentModule = module;
            };

            self.startLoading = function() {
                self.disableButtons();
                loading = true;
            };

            self.stopLoading = function() {
                self.enableButtons();
                loading = false;
            };

            self.isLoading = function() {
                return loading;
            };

            self.disableButtons = function() {
                disabled = true;
            };

            self.enableButtons = function() {
                disabled = false;
            };

            self.isButtonEnabled = function(button) {
                return !disabled;
            };

            self.isButtonDisabled = function(button) {
                return disabled;
            };

            /**
             * Titles
             */
            var titles = {
                'previous': Translator.translate('forms.action-buttons.previous'),
                'next': Translator.translate('forms.action-buttons.next'),
                'cancel': Translator.translate('forms.action-buttons.cancel'),
                'save': Translator.translate('forms.action-buttons.save'),
                'complete': Translator.translate('forms.action-buttons.complete')
            };

            self.setTitle = function(button, title) {
                titles[button] = title;
            };

            self.getTitle = function(button) {
                return titles[button];
            };

            /**
             * Show & Hide
             */
            var globalVisibility = {};
            var moduleVisibility = {};

            self.hasVisibility = function(module, button) {
                return self.hasGlobalVisibility(button) || self.hasModuleVisibility(module, button);
            };

            self.hasGlobalVisibility = function(button) {
                return typeof globalVisibility[button] === 'boolean';
            };

            self.hasModuleVisibility = function(module, button) {
                if (typeof moduleVisibility[module] === 'undefined') {
                    return false;
                }

                return typeof moduleVisibility[module][button] === 'boolean';
            };

            self.setGlobalVisibility = function(button, state) {
                globalVisibility[button] = state;
            };

            self.setModuleVisibility = function(module, button, state) {
                if (typeof moduleVisibility[module] === 'undefined') {
                    moduleVisibility[module] = {};
                }

                moduleVisibility[module][button] = state;
            };

            self.showButtonGlobally = function(button) {
                self.setGlobalVisibility(button, true);
            };

            self.hideButtonGlobally = function(button) {
                self.setGlobalVisibility(button, false);
            };

            self.showButtonForModule = function(module, button) {
                self.setModuleVisibility(module, button, true);
            };

            self.hideButtonForModule = function(module, button) {
                self.setModuleVisibility(module, button, false);
            };

            self.isButtonVisible = function(button) {
                if (self.hasGlobalVisibility(button)) {
                    return globalVisibility[button];
                }

                if (self.hasModuleVisibility(currentModule, button)) {
                    return moduleVisibility[currentModule][button];
                }

                return false;
            };

            /**
             * Hooks
             */
            var globalPreHooks = {};
            var globalPostHooks = {};
            var modulePreHooks = {};
            var modulePostHooks = {};

            function getGlobalHooks(hooks, button) {
                if (typeof hooks[button] === 'undefined') {
                    return {};
                }

                return hooks[button];
            }

            function setGlobalHook(hooks, button, priority, action) {
                if (typeof hooks[button] === 'undefined') {
                    hooks[button] = {};
                }

                hooks[button][priority] = action;
            }

            function removeGlobalHook(hooks, button, priority) {
                if (typeof hooks[button] === 'undefined') {
                    return;
                }

                delete hooks[button][priority];
            }

            function getModuleHooks(hooks, module, button) {
                if (typeof hooks[module] === 'undefined') {
                    return {};
                }

                if (typeof hooks[module][button] === 'undefined') {
                    return {};
                }

                return hooks[module][button];
            }

            function setModuleHook(hooks, module, button, priority, action) {
                if (typeof hooks[module] === 'undefined') {
                    hooks[module] = {};
                }

                if (typeof hooks[module][button] === 'undefined') {
                    hooks[module][button] = {};
                }

                hooks[module][button][priority] = action;
            }

            function removeModuleHook(hooks, module, button, priority) {
                if (typeof hooks[module] === 'undefined') {
                    return;
                }

                if (typeof hooks[module][button] === 'undefined') {
                    return;
                }

                delete hooks[module][button][priority];
            }

            function executeHooks(globalHooks, moduleHooks, params) {
                var hooks = {};
                var priority;

                for (priority in moduleHooks) {
                    if (!moduleHooks.hasOwnProperty(priority)) continue;

                    hooks[priority] = moduleHooks[priority];
                }

                for (priority in globalHooks) {
                    if (!globalHooks.hasOwnProperty(priority)) continue;

                    hooks[priority] = globalHooks[priority];
                }

                var priorities = Object.keys(hooks).sort(function(a, b) {
                    return a - b;
                }).reverse();

                for (var i = 0; i < priorities.length; i++) {
                    priority = priorities[i];

                    if (! hooks[priority](params)) {
                        return false;
                    }
                }

                return true;
            }

            function executePreHooks(module, button, params) {
                var globalHooks = getGlobalHooks(globalPreHooks, button);
                var moduleHooks = getModuleHooks(modulePreHooks, module, button);

                return executeHooks(globalHooks, moduleHooks, params);
            }

            function executePostHooks(module, button, params) {
                var globalHooks = getGlobalHooks(globalPostHooks, button);
                var moduleHooks = getModuleHooks(modulePostHooks, module, button);

                return executeHooks(globalHooks, moduleHooks, params);
            }

            self.setGlobalPreHook = function(button, priority, action) {
                setGlobalHook(globalPreHooks, button, priority, action);
            };

            self.setGlobalPostHook = function(button, priority, action) {
                setGlobalHook(globalPostHooks, button, priority, action);
            };

            self.removeGlobalPreHook = function(button, priority) {
                removeGlobalHook(globalPreHooks, button, priority);
            };

            self.removeGlobalPostHook = function(button, priority) {
                removeGlobalHook(globalPostHooks, button, priority);
            };

            self.setModulePreHook = function(module, button, priority, action) {
                setModuleHook(modulePreHooks, module, button, priority, action);
            };

            self.setModulePostHook = function(module, button, priority, action) {
                setModuleHook(modulePostHooks, module, button, priority, action);
            };

            self.removeModulePreHook = function(module, priority, button) {
                removeModuleHook(modulePreHooks, module, priority, button);
            };

            self.removeModulePostHook = function(module, priority, button) {
                removeModuleHook(modulePostHooks, module, priority, button);
            };

            /**
             * Actions
             */
            var globalActions = {};
            var moduleActions = {};

            var defaultAction = function() {
                return true;
            };

            function getGlobalAction(button) {
                if (hasGlobalAction(button)) {
                    return globalActions[button];
                }

                return defaultAction;
            }

            function getModuleAction(module, button) {
                if (hasModuleAction(module, button)) {
                    return moduleActions[module][button];
                }

                return defaultAction;
            }

            function hasGlobalAction(button) {
                return typeof globalActions[button] === 'function';
            }

            function hasModuleAction(module, button) {
                if (typeof moduleActions[module] === 'undefined') {
                    return false;
                }

                return typeof moduleActions[module][button] === 'function';
            }

            function executeAction(module, button, params) {
                var action = defaultAction();

                if (hasGlobalAction(button)) {
                    action = getGlobalAction(button);
                } else {
                    action = getModuleAction(module, button)
                }

                return action(params);
            }

            self.setGlobalAction = function(button, action) {
                globalActions[button] = action;
            };

            self.setModuleAction = function(module, button, action) {
                if (typeof moduleActions[module] === 'undefined') {
                    moduleActions[module] = {};
                }

                moduleActions[module][button] = action;
            };

            /**
             * Call
             */
            self.call = function(button, params) {
                var module = currentModule;

                return executePreHooks(module, button, params)
                    && executeAction(module, button, params)
                    && executePostHooks(module, button, params);
            };
        }

        return {
            getInstance: function() {
                return new ActionButtonsManager();
            }
        }
    });