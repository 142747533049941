'use strict';

angular.module('forms.overview',
    [
        'forms.components.usp',
        'rol.bootstrap-modal',
        'rol.translation'
    ]
)

    .directive('overview', function($window, Translator, hasUSP) {
        return {
            restrict: 'E',
            scope: {
                'type': '=',
                'categories': '=',
                'signalizations': '=',
                'startContractWizard': '=',
                'startSignalizationWizard': '=',
                'startSepaWizard': '='
            },
            templateUrl: 'overview/overview.tpl.html',

            link: function(scope) {

                scope.isContractOverview = function() {
                    return scope.type === 'contract';
                };

                scope.isTemplateOverview = function() {
                    return scope.type === 'template';
                };

                scope.getTypeClass = function() {
                    return scope.isTemplateOverview() ? 'forms__overview__section--templates' : '';
                };

                scope.openUspUrl = function(salesChannel) {
                    $window.open(Translator.translateVariable('usp_file', salesChannel), '_blank');
                };

                // Helper function from forms.components.usp
                scope.hasUSP = hasUSP;
            }
        };
    });