'use strict';

angular.module('forms.components.usp',
    [
        'rol.translation'
    ]
)

    .directive('usp', function() {
        return {
            restrict: 'E',
            scope: {
                salesChannel: '='
            },
            templateUrl: 'components/usp/usp.tpl.html'
        };
    })

    .factory('hasUSP', function(Translator) {
        return function(salesChannel) {
            return !!(
                Translator.translateVariable('usp', salesChannel)
                || Translator.translateVariable('target', salesChannel)
                || Translator.translateVariable('usp_file', salesChannel)
            );
        };
    });