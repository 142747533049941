'use strict';

angular.module('forms.helper', [])

    .directive('emptyOnDisable', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelController) {
                scope.$watch(attrs.ngDisabled, function(disabled) {
                    if (disabled) {
                        ngModelController.$setViewValue(undefined);
                        ngModelController.$render();
                    }
                })
            }
        }
    })


    .directive('formOnChange', function($parse) {
        return {
            require: 'form',
            link: function(scope, element, attrs){
                var callback = $parse(attrs.formOnChange);
                element.on('change', function() {
                    callback(scope);
                });
            }
        };
    })

    
    .filter('getNestedValue', function() {
        return function(baseObject, path) {
            var value = angular.copy(baseObject);
            var levels = path.split('.');

            for (var i = 0; i < levels.length; i++) {
                if (!value || !value.hasOwnProperty(levels[i])) {
                    return null;
                }
                value = value[levels[i]];
            }
            return value;
        };
    })


    .filter('isEmpty', function() {
        return function(object) {
            return angular.equals({}, object);
        }
    })


    .filter('currency', function() {
        return function(number) {
            return (Math.round(parseFloat(number) * 100) / 100).toFixed(2)
                .replace(/\./, ',')
                .replace(/(^\d{1,3}|\d{3})(?=(?:\d{3})+(?:$|,))/g, '$1.') + ' €';
        };
    })


    .factory('extractNestedValue', [function() {
        return function(object, path) {
            var value = angular.copy(object);

            var levels = path.split('.');

            for (var i = 0; i < levels.length; i++) {
                if (!value || !value.hasOwnProperty(levels[i])) {
                    return null;
                }
                value = value[levels[i]];
            }
            return value;
        }
    }])


    .factory('grabDefinition', ['searchDefinition', function(searchDefinition) {
        return function(info, data) {
            var id = data['forms']['object_id'];
            var type = data['forms']['object_type'];

            var definition = searchDefinition(info.definitions, type, id);

            if(definition !== null) {
                return definition;
            }

            for(var j = 0; j < info.definitions['categories'].length; j++) {
                definition = searchDefinition(info.definitions['categories'][j], type, id);

                if(definition !== null) {
                    return definition;
                }
            }

            return null;
        }
    }])


    .factory('searchDefinition', function() {
        return function(definitions, type, id) {
            var definitionsOfType = [];

            if(type === 'bundle') {
                definitionsOfType = definitions['bundles'];
            } else if (type === 'virtual-bundle') {
                definitionsOfType = definitions['virtual_bundles'];
            }

            for(var i = 0; i < definitionsOfType.length; i++) {
                if(definitionsOfType[i]['id'] === id) {
                    return definitionsOfType[i];
                }
            }

            return null;
        }
    });