'use strict';

angular.module('forms.modules.signalization',
    [
        'forms.helper',
        'rol.form.validation',
        'rol.translation'
    ]
)

    .directive('signalization', function(Translator) {
        return {
            restrict: 'E',
            scope: {
                'actions': '=',
                'parameters': '=',
                'info': '=',
                'data': '='
            },
            templateUrl: 'modules/signalization/signalization.tpl.html',
            link: function (scope) {

                /*****************************************
                 * Commissions
                 *****************************************/
                scope.commissions = [];

                function prepareCommissions() {
                    if (scope.info.signalization && scope.info.signalization.reseller_groups) {
                        for (var i = 0; i < scope.info.signalization.reseller_groups.length; i++) {
                            var commission = Translator.translateVariable(
                                'commission',
                                scope.info.signalization.reseller_groups[i]
                            );
                            if (commission) {
                                scope.commissions.push(commission);
                            }
                        }
                    }
                }

                /*****************************************
                 * Validation
                 *****************************************/
                scope.hasValidationErrors = function() {
                    return scope.signalizationForm.$submitted && !scope.signalizationForm.$valid;
                };

                scope.getValidationClass = function(field) {
                    if(scope['signalizationForm'].$submitted && scope['signalizationForm'][field].$invalid) {
                        return 'forms__label--error';
                    }

                    return '';
                };

                /*****************************************
                 * Actions
                 *****************************************/
                scope.actions.validate = function () {
                    scope.signalizationForm.$setSubmitted();

                    return (scope.signalizationForm.$valid === true);
                };

                /*****************************************
                 * Init
                 *****************************************/
                function init() {
                    // Description InfoBox
                    if (typeof scope.info.signalization == 'object') {
                        scope.description = Translator.translateVariable('description', scope.info.signalization);
                    }

                    prepareCommissions();
                }

                init();
            }
        };
    })

    .service('SignalizationSummaryService', function(Translator, extractNestedValue) {
        return {
            getSummary: function(info, data, parameters) {
                return [
                    {
                        'section': Translator.translate('forms.signalization.contact_person'),
                        'data': [
                            {
                                'identifier': 'signalization.contact_name',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'signalization.contact_number',
                                'type': 'key-value'
                            }
                        ]
                    },
                    {
                        'section': Translator.translate('forms.signalization.personal_conversation_title'),
                        'data': [
                            {
                                'identifier': 'signalization.personal_conversation',
                                'type': 'value-only',
                                'value': extractNestedValue(data, 'signalization.personal_conversation')
                                    ? Translator.translate('forms.signalization.personal_conversation')
                                    : null
                            }
                        ]
                    },
                    {
                        'section': Translator.translate('forms.signalization.comment'),
                        'data': [
                            {
                                'identifier': 'signalization.comment',
                                'type': 'value-only'
                            }
                        ]
                    },
                    {
                        'section': Translator.translate('forms.signalization.order_data'),
                        'data': [
                            {
                                'identifier': 'signalization.order_data_bank',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'signalization.order_data_office',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'signalization.order_data_employee',
                                'type': 'key-value'
                            }
                        ]
                    }
                ];
            }
        };
    });