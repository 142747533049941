'use strict';

angular.module('forms.modules.forms.bundle',
    [
        'forms.modules.forms.bundle-items',
        'forms.modules.forms.bundle-price',
        'rol.translation'
    ]
)

    .directive('bundleForm', function() {
        return {
            restrict: 'E',
            scope: {
                'definition': '=',
                'parameters': '=',
                'data': '=',
                'form': '=',
                'bundleInformation': '=',

                'hasValidDependencies': '='
            },
            templateUrl: 'modules/forms/bundle-form/bundle-form.tpl.html',

            link: function(scope) {
                scope.hasImage = function() {
                    return typeof scope.definition.article !== 'undefined'
                        && scope.definition.article.image_url_small !== null;
                };
            }
        };
    });