'use strict';

angular.module('forms.notifications.finish',
    [
        'forms.env',
        'rol.translation'
    ]
)

    .directive('finish', function(env) {
        return {
            restrict: 'E',
            scope: {
                'finish': '=',
                'close': '='
            },
            templateUrl: 'notifications/finish/finish.tpl.html',

            link: function(scope) {

                scope.showContractDownload = function() {
                    return (typeof scope['finish'] !== 'undefined' && typeof scope['finish']['contract'] !== 'undefined');
                };

                scope.showSepaDownload = function() {
                    return (typeof scope['finish'] !== 'undefined' && typeof scope['finish']['sepa'] !== 'undefined');
                };

                scope.getContractDownloadLink = function() {
                    return env['baseUrl'] + '/pdf/download/' + scope['finish']['contract'];
                };

                scope.getSepaDownloadLink = function() {
                    return env['baseUrl'] + '/pdf/download/' + scope['finish']['sepa'];
                };
                
            }
        };
    });