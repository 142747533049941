'use strict';

angular.module('forms.container',
    [
        'forms.overview',
        'forms.wizard',
        'forms.notifications.save',
        'forms.notifications.finish',
        'forms.services.info',
        'forms.services.data',
        'forms.services.categories',
        'forms.services.contracts',
        'forms.services.signalizations',
        'forms.services.sepa',
        'forms.services.templates',
        'forms.services.customers',
        'forms.action-buttons',
        'forms.information-stack',
        'rol.translation',
        'user'
    ]
)

    .directive('containerContracts', function($location, DataService, CategoriesService, ContractsService,
                                     SignalizationsService, SepaService, RolCustomersService, FormsCustomersService,
                                     Translator, wizardManager, ActionButtonsManager, resellerGroups) {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: 'container/container.tpl.html',

            link: function(scope) {

                var customerHiddenFields = [];

                if (resellerGroups.indexOf('Employee::ResellerGroup') < 0) {
                    customerHiddenFields.push('rol_customer_import');
                }

                scope.type = 'contract';

                /**********************************************************
                 * Contract Form
                 **********************************************************/
                scope.startBlankContractWizard = function(category) {
                    var definition = null;
                    var data = null;

                    function startContractWizard() {
                        if(definition !== null && data !== null) {
                            scope.startContractWizard(definition, data);
                        }
                    }

                    wizardManager.setLoading('CategoriesService');
                    wizardManager.setLoading('PrepareData');

                    CategoriesService.get(category.id, function(apiDefinition) {
                        definition = apiDefinition;
                        startContractWizard();
                        wizardManager.unsetLoading('CategoriesService');
                    }, function() {
                        scope.setCurrent('error');
                        wizardManager.unsetLoading('CategoriesService');
                    });

                    DataService.prepare(function(apiData) {
                        data = apiData;
                        startContractWizard();
                        wizardManager.unsetLoading('PrepareData');
                    }, function() {
                        scope.setCurrent('error');
                        wizardManager.unsetLoading('PrepareData');
                    });
                };

                scope.startDraftContractWizard = function(id) {
                    wizardManager.setDraftId(id);

                    var definition = null;
                    var data = null;

                    function startContractWizard() {
                        if(definition !== null && data !== null) {
                            scope.startContractWizard(definition, data);
                        }
                    }

                    function requestData() {
                        wizardManager.setLoading('requestData');
                        ContractsService.get(id, function(apiData) {
                            data = apiData;
                            requestDefinition();
                            wizardManager.unsetLoading('requestData');
                        }, function() {
                            scope.setCurrent('error');
                            wizardManager.unsetLoading('requestData');
                        });
                    }

                    function requestDefinition() {
                        var objectType = data['forms']['object_type'];
                        var objectId = data['forms']['object_id'];
                        var requestFunction = null;

                        if(objectType === 'bundle') {
                            requestFunction = CategoriesService.getByBundle;
                        } else if(objectType === 'virtual-bundle') {
                            requestFunction = CategoriesService.getByVirtualBundle;
                        } else {
                            scope.setCurrent('error');
                            return;
                        }

                        wizardManager.setLoading('requestDefinition');
                        requestFunction(objectId, function(result) {
                            definition = result;
                            startContractWizard();
                            wizardManager.unsetLoading('requestDefinition');
                        }, function() {
                            scope.setCurrent('error');
                            wizardManager.unsetLoading('requestDefinition');
                        });
                    }

                    requestData();
                };

                scope.startContractWizard = function(category, data) {
                    // Prepare Wizard
                    var config = {
                        actionButtonsManager: ActionButtonsManager.getInstance()
                    };

                    config.actionButtonsManager.hideButtonForModule('service', 'next');
                    config.actionButtonsManager.showButtonForModule('forms', 'save');
                    config.actionButtonsManager.showButtonForModule('billing', 'save');
                    config.actionButtonsManager.showButtonForModule('order', 'save');
                    config.actionButtonsManager.showButtonForModule('summary', 'save');
                    config.actionButtonsManager.showButtonForModule('summary', 'complete');
                    config.actionButtonsManager.showButtonGlobally('cancel');

                    config.actionButtonsManager.setGlobalAction('save', wizardManager.save);
                    config.actionButtonsManager.setGlobalAction('complete', wizardManager.complete);
                    config.actionButtonsManager.setGlobalAction('cancel', wizardManager.cancel);
                    config.actionButtonsManager.setTitle('complete', Translator.translate('forms.summary.complete-contract'));

                    var modules = [
                        {
                            'name': 'customer',
                            'breadcrumb': Translator.translate('forms.modules.titles.customer-data'),
                            'parameters': {
                                'rolCustomersService': RolCustomersService,
                                'formsCustomersService': FormsCustomersService,
                                'hiddenFields' : customerHiddenFields
                            }
                        },
                        {
                            'name': 'service',
                            'breadcrumb': Translator.translate('forms.modules.titles.service'),
                            'parameters': {
                                'type': 'contract'
                            }
                        },
                        {
                            'name': 'forms',
                            'breadcrumb': Translator.translate('forms.modules.titles.forms'),
                            'parameters': {
                                'forceWithoutVat': true
                            }
                        },
                        {
                            'name': 'billing',
                            'breadcrumb': Translator.translate('forms.modules.titles.billing'),
                            'parameters': {}
                        },
                        {
                            'name': 'order',
                            'breadcrumb': Translator.translate('forms.modules.titles.order'),
                            'parameters': {}
                        },
                        {
                            'name': 'summary',
                            'breadcrumb': Translator.translate('forms.modules.titles.summary'),
                            'parameters': {
                                'type': 'contract',
                                'modules': [
                                    'customer',
                                    'service',
                                    'forms',
                                    'billing',
                                    'order'
                                ],
                                'infoText': Translator.translate('forms.modules.summary.info-text.contract'),
                                'validation_errors': [],
                                'forceWithoutVat': true
                            }
                        }
                    ];
                    
                    var info = {
                        'type': 'contract',
                        'category': {
                            'id': category.id,
                            'code': category.code,
                            'name_de': category.name_de,
                            'name_it': category.name_it,
                            'name_en': category.name_en
                        },
                        'definitions': {
                            'categories': category['categories'],
                            'virtual_bundles': category['virtual_bundles'],
                            'bundles': category['bundles']
                        },
                        'agb_templates': category['agb_templates'],
                        'filters': category['filters'],
                        'default_payment_options': category['default_payment_options']
                    };

                    wizardManager.prepareInformationStacks(info, category);
                    wizardManager.setSaveMethod(ContractsService.save);
                    wizardManager.setCompleteMethod(ContractsService.complete);
                    wizardManager.startWizard(config, modules, info, data);
                };

                /**********************************************************
                 * Signalization Form
                 **********************************************************/
                scope.startBlankSignalizationWizard = function(signalization) {
                    wizardManager.setLoading('PrepareData');

                    var successCallback = function(data) {
                        scope.startSignalizationWizard(signalization, data);
                        wizardManager.unsetLoading('PrepareData');
                    };

                    var failureCallback = function() {
                        scope.setCurrent('error');
                        wizardManager.unsetLoading('PrepareData');
                    };

                    DataService.prepare(successCallback, failureCallback);
                };

                scope.startSignalizationWizard = function(signalization, data) {
                    // Prepare Wizard
                    var config = {
                        actionButtonsManager: ActionButtonsManager.getInstance()
                    };

                    config.actionButtonsManager.showButtonForModule('summary', 'complete');
                    config.actionButtonsManager.showButtonGlobally('cancel');

                    config.actionButtonsManager.setGlobalAction('save', wizardManager.save);
                    config.actionButtonsManager.setGlobalAction('complete', wizardManager.complete);
                    config.actionButtonsManager.setGlobalAction('cancel', wizardManager.cancel);
                    config.actionButtonsManager.setTitle('complete', Translator.translate('forms.summary.complete-signalization'));

                    var modules = [
                        {
                            'name': 'customer',
                            'breadcrumb': Translator.translate('forms.modules.titles.customer-data'),
                            'parameters': {
                                'rolCustomersService': RolCustomersService,
                                'formsCustomersService': FormsCustomersService,
                                'hiddenFields' : customerHiddenFields
                            }
                        },
                        {
                            'name': 'signalization',
                            'breadcrumb': Translator.translate('forms.modules.titles.signalization'),
                            'parameters': {}
                        },
                        {
                            'name': 'summary',
                            'breadcrumb': Translator.translate('forms.modules.titles.summary'),
                            'parameters': {
                                'type': 'signalization',
                                'modules': [
                                    'customer',
                                    'signalization'
                                ],
                                'infoText': Translator.translate('forms.modules.summary.info-text.signalization'),
                                'validation_errors': []
                            }
                        }
                    ];
                    var info = {
                        'type': 'signalization',
                        'signalization': signalization
                    };

                    if(typeof data['signalization'] !== 'object') {
                        data['signalization'] = {};
                    }

                    data['signalization']['object_id'] = signalization.id;
                    data['signalization']['object_type'] = signalization.type;

                    wizardManager.prepareInformationStacks(info, signalization);
                    wizardManager.setSaveMethod(SignalizationsService.save);
                    wizardManager.setCompleteMethod(SignalizationsService.complete);
                    wizardManager.startWizard(config, modules, info, data);
                };

                /**********************************************************
                 * Sepa Form
                 **********************************************************/
                scope.startBlankSepaWizard = function() {
                    wizardManager.setLoading('PrepareData');

                    var successCallback = function(data) {
                        scope.startSepaWizard(data);
                        wizardManager.unsetLoading('PrepareData');
                    };

                    var failureCallback = function() {
                        scope.setCurrent('error');
                        wizardManager.unsetLoading('PrepareData');
                    };

                    DataService.prepare(successCallback, failureCallback);
                };

                scope.startSepaWizard = function(data) {
                    // Prepare Wizard

                    var config = {
                        actionButtonsManager: ActionButtonsManager.getInstance()
                    };

                    config.actionButtonsManager.showButtonForModule('summary', 'complete');
                    config.actionButtonsManager.showButtonGlobally('cancel');

                    config.actionButtonsManager.setGlobalAction('save', wizardManager.save);
                    config.actionButtonsManager.setGlobalAction('complete', wizardManager.complete);
                    config.actionButtonsManager.setGlobalAction('cancel', wizardManager.cancel);
                    config.actionButtonsManager.setTitle('complete', Translator.translate('forms.summary.complete-sepa'));

                    var modules = [
                        {
                            'name': 'customer',
                            'breadcrumb': Translator.translate('forms.modules.titles.customer-data'),
                            'parameters': {
                                'rolCustomersService': RolCustomersService,
                                'formsCustomersService': FormsCustomersService,
                                'hiddenFields' : customerHiddenFields
                            }
                        },
                        {
                            'name': 'sepa',
                            'breadcrumb': Translator.translate('forms.modules.titles.sepa'),
                            'parameters': {}
                        },
                        {
                            'name': 'order',
                            'breadcrumb': Translator.translate('forms.modules.titles.signalization'),
                            'parameters': {
                                'hideSections': [
                                    'comment',
                                    'hardware_delivery'
                                ]
                            }
                        },
                        {
                            'name': 'summary',
                            'breadcrumb': Translator.translate('forms.modules.titles.summary'),
                            'parameters': {
                                'type': 'sepa',
                                'modules': [
                                    'customer',
                                    'sepa',
                                    'order'
                                ],
                                'infoText': Translator.translate('forms.modules.summary.info-text.sepa'),
                                'validation_errors': []
                            }
                        }
                    ];
                    var info = {
                        'type': 'sepa'
                    };

                    wizardManager.setSaveMethod(SepaService.save);
                    wizardManager.setCompleteMethod(SepaService.complete);
                    wizardManager.startWizard(config, modules, info, data);
                };


                /**********************************************************
                 * Init Form
                 **********************************************************/
                function loadSuccessCallback() {
                    // Route Parameter
                    var routeParams = $location.search();

                    if(typeof routeParams['type'] !== 'undefined' && typeof routeParams['id'] !== 'undefined' && routeParams['type'] === 'contract') {
                        scope.startDraftContractWizard(routeParams['id']);
                    } else {
                        scope.setCurrent('overview');
                    }
                }

                function loadFailureCallback() {}

                function resetCallback() {
                    $location.path('/');
                    $location.search({});
                    $location.replace();
                }

                scope['wizardManager'] = wizardManager;
                wizardManager.setup(scope, resetCallback);
                wizardManager.load(loadSuccessCallback, loadFailureCallback);
            }
        };
    })


    .directive('containerTemplates', function(CategoriesService, TemplatesService, Translator,
                                              wizardManager, ActionButtonsManager) {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: 'container/container.tpl.html',

            link: function(scope) {

                scope.type = 'template';

                /**********************************************************
                 * Contract Template
                 **********************************************************/
                scope.startBlankContractWizard = function(category) {
                    wizardManager.setLoading('CategoriesService');

                    CategoriesService.get(category.id, function(definition) {
                        scope.startContractWizard(definition, {});
                        wizardManager.unsetLoading('CategoriesService');
                    }, function() {
                        scope.setCurrent('error');
                        wizardManager.unsetLoading('CategoriesService');
                    });
                };

                scope.startContractWizard = function(category, data) {

                    var config = {
                        actionButtonsManager: ActionButtonsManager.getInstance()
                    };

                    config.actionButtonsManager.showButtonGlobally('cancel');

                    config.actionButtonsManager.setGlobalAction('complete', wizardManager.complete);
                    config.actionButtonsManager.setGlobalAction('cancel', wizardManager.cancel);

                    var modules = [
                        {
                            'name': 'service',
                            'breadcrumb': Translator.translate('forms.modules.titles.service'),
                            'parameters': {
                                'type': 'template'
                            }
                        }
                    ];

                    var info = {
                        'type': 'contract',
                        'category': {
                            'id': category.id,
                            'code': category.code,
                            'name_de': category.name_de,
                            'name_it': category.name_it,
                            'name_en': category.name_en
                        },
                        'definitions': {
                            'categories': category['categories'],
                            'virtual_bundles': category['virtual_bundles'],
                            'bundles': category['bundles']
                        },
                        'agb_templates': {},
                        'filters': {}
                    };

                    wizardManager.prepareInformationStacks(info, category);
                    wizardManager.setCompleteMethod(TemplatesService.print);
                    wizardManager.setCompleteModule('overview');
                    wizardManager.startWizard(config, modules, info, data);
                };

                /**********************************************************
                 * Sepa Template
                 **********************************************************/
                scope.startBlankSepaWizard = function() {
                    wizardManager.setCompleteMethod(TemplatesService.print);
                    wizardManager.setCompleteModule('overview');

                    scope.wizard.data.template = {
                        'object_type': 'sepa'
                    };
                    scope.wizardManager.complete();
                };

                /**********************************************************
                 * Init
                 **********************************************************/
                function loadSuccessCallback() {
                    scope.setCurrent('overview');
                }

                function loadFailureCallback() {}

                scope['wizardManager'] = wizardManager;
                wizardManager.setup(scope, function(){});
                wizardManager.load(loadSuccessCallback, loadFailureCallback);
            }
        };
    })

    .factory('wizardManager', function($filter, InfoService, InformationStack, Translator) {

        var scope = null;

        var resetCallback = null;

        var serviceSaveMethod = null;
        var serviceCompleteMethod = null;
        var serviceCompleteModule = 'finish';
        var serviceDraftId = null;

        // Setup
        function setup(s, r) {
            scope = s;
            resetCallback = r;

            // Defaults
            scope.current = 'loading';

            scope.categories = [];
            scope.signalizations = [];

            scope.wizard = {
                config: {},
                modules: [],
                info: {},
                data: {}
            };

            // Presentation
            scope.setCurrent = function(name) {
                scope.current = name;
            };

            scope.isCurrent = function(name) {
                return scope.current === name;
            };
        }

        // Init
        function load(successCallback, failureCallback) {
            // Init
            setLoading('init');

            InfoService.all(function(data) {
                // Categories
                scope.categories = data.categories;

                // Signalizations
                scope.signalizations = [];

                data.signalizations['categories'].forEach(function(signalization) {
                    signalization['type'] = 'category';
                    scope.signalizations.push(signalization);
                });

                data.signalizations['virtual_bundles'].forEach(function(signalization) {
                    signalization['type'] = 'virtual-bundle';
                    scope.signalizations.push(signalization);
                });

                data.signalizations['bundles'].forEach(function(signalization) {
                    signalization['type'] = 'bundle';
                    scope.signalizations.push(signalization);
                });

                successCallback();
                unsetLoading('init');
            }, function() {
                scope.setCurrent('error');

                failureCallback();
                unsetLoading('init');
            });
        }

        // Actions
        function complete() {
            setLoading('complete');

            serviceCompleteMethod(serviceDraftId, scope.wizard.data, function(data) {
                scope.finish = data;
                scope.setCurrent(serviceCompleteModule);
                reset();

                unsetLoading('complete');
            }, function(response) {
                if(response.status !== 400) {
                    scope.setCurrent('error');
                    reset();
                } else {
                    // Validation error
                    for(var i = 0; i < scope.wizard.modules.length; i++) {
                        var module = scope.wizard.modules[i];

                        if (module.name == 'summary') {
                            module.parameters.validation_errors.splice(0, module.parameters.validation_errors.length);

                            for(var j = 0; j < response.data.errors.length; j++) {
                                var error = response.data.errors[j];

                                module.parameters.validation_errors.push({
                                    'identifier': error.property
                                });
                            }

                            break;
                        }
                    }
                }

                unsetLoading('complete');
            });
        }

        function save() {
            setLoading('save');

            serviceSaveMethod(serviceDraftId, scope.wizard.data, function(data) {
                scope.setCurrent('save');
                unsetLoading('save');
            }, function(response) {
                scope.setCurrent('error');
                unsetLoading('save');
            });

            reset();
        }

        function cancel() {
            reset();
            scope.setCurrent('overview');
        }

        function close() {
            reset();
            scope.setCurrent('overview');
        }

        function reset() {
            serviceSaveMethod = null;
            serviceCompleteMethod = null;
            serviceDraftId = null;

            resetCallback();

            scope.$broadcast('wizard.stop');
        }

        // Wizard
        function prepareInformationStacks(info, object) {
            var titleStack = InformationStack.getInstance();
            var salesChannelStack = InformationStack.getInstance();

            var prefix = '';

            switch(info['type']) {
                case 'contract':
                    prefix = Translator.translate('forms.wizard.contract') + ': ';
                    break;
                case 'signalization':
                    prefix = Translator.translate('forms.wizard.signalization') + ': ';
                    break;
                case 'sepa':
                    prefix = Translator.translate('forms.wizard.sepa') + ': ';
                    break;
                default:
                    break;
            }

            titleStack.insert(0, prefix + $filter('translateVariable')('name', object));
            salesChannelStack.insert(0, object.sales_channel);

            info['title'] = titleStack;
            info['sales_channel'] = salesChannelStack;
        }

        function setSaveMethod(method) {
            serviceSaveMethod = method;
        }

        function setCompleteMethod(method) {
            serviceCompleteMethod = method;
        }

        function setCompleteModule(module) {
            serviceCompleteModule = module;
        }

        function setDraftId(id) {
            serviceDraftId = id;
        }

        function startWizard(config, modules, info, data) {
            angular.copy(config, scope.wizard.config);
            angular.copy(modules, scope.wizard.modules);
            angular.copy(info, scope.wizard.info);
            angular.copy(data, scope.wizard.data);

            scope.$broadcast('wizard.start');
            scope.setCurrent('wizard');
        }

        // Loading
        var loading = [];

        function isLoading() {
            return !!loading.length;
        }

        function setLoading(id) {
            loading.push(id);
        }

        function unsetLoading(id) {
            var index = loading.indexOf(id);
            if (index > -1) {
                loading.splice(index, 1);
            }
        }

        return {
            'setup': setup,
            'load': load,

            'prepareInformationStacks': prepareInformationStacks,
            'setSaveMethod': setSaveMethod,
            'setCompleteMethod': setCompleteMethod,
            'setCompleteModule': setCompleteModule,
            'setDraftId': setDraftId,
            'startWizard': startWizard,

            'isLoading': isLoading,
            'setLoading': setLoading,
            'unsetLoading': unsetLoading,

            'complete': complete,
            'save': save,
            'cancel': cancel,
            'close': close
        };
    });
