'use strict';

angular.module('forms.services.categories',
    [
        'forms.config',
        'forms.env',
        'rol.api'
    ]
    )

    .service('CategoriesService', function (env, ApiService, $http) {

        function get(id, successCallback, failureCallback) {
            $http({
                method: 'GET',
                url: env['apiUrl'] + 'categories/' + id
            }).then(ApiService.successMiddleware(successCallback), ApiService.failureMiddleware(failureCallback));
        }

        function getByBundle(id, successCallback, failureCallback) {
            $http({
                method: 'GET',
                url: env['apiUrl'] + 'category-by-bundle/' + id
            }).then(ApiService.successMiddleware(successCallback), ApiService.failureMiddleware(failureCallback));
        }

        function getByVirtualBundle(id, successCallback, failureCallback) {
            $http({
                method: 'GET',
                url: env['apiUrl'] + 'category-by-virtualbundle/' + id
            }).then(ApiService.successMiddleware(successCallback), ApiService.failureMiddleware(failureCallback));
        }

        return {
            get: get,
            getByBundle: getByBundle,
            getByVirtualBundle: getByVirtualBundle
        };
    });