'use strict';

angular.module('forms.modules.billing',
    [
        'forms.helper',
        'rol.form.validation',
        'rol.translation'
    ]
)

    .directive('billing', function(grabDefinition) {
        return {
            restrict: 'E',
            scope: {
                'actions': '=',
                'parameters': '=',
                'info': '=',
                'data': '='
            },
            templateUrl: 'modules/billing/billing.tpl.html',
            link: function(scope) {

                /*****************************************
                 * Preparation
                 *****************************************/
                scope.prepareData = function() {
                    switch(scope.data['forms']['object_type']) {
                        case 'bundle':
                            scope.paymentOptions = getPaymentOptionsForBundle(scope.definition, false);
                            break;
                        case 'virtual-bundle':
                            scope.paymentOptions = getPaymentOptionsForVirtualBundle();
                            break;
                    }
                };

                function getPaymentOptionsForVirtualBundle() {
                    var paymentOptions = null;

                    var virtualBundleItems = scope.definition['virtual_bundle_items'];
                    if (virtualBundleItems != undefined) {
                        virtualBundleItems.forEach(function(virtualBundleItem) {
                            if (virtualBundleItem['items'] != undefined) {
                                virtualBundleItem['items'].forEach(function(item) {
                                    if (isVirtualBundleItemItemSelected(virtualBundleItem, item)) {
                                        paymentOptions = intersectPaymentOptions(paymentOptions, item);
                                    }
                                });
                            }
                        });
                    }

                    if (paymentOptions == null) {
                        paymentOptions = angular.copy(scope.defaultPaymentOptions);
                    }

                    return paymentOptions;
                }

                function isVirtualBundleItemItemSelected(virtualBundleItem, item) {
                    var virtualBundleItemItem = scope.data['forms']['items'][virtualBundleItem['id']];

                    return virtualBundleItemItem != undefined
                        && virtualBundleItemItem['bundle'] != null
                        && virtualBundleItemItem['bundle']['object_id'] == item['bundle']['id']
                }

                function intersectPaymentOptions(paymentOptions, item) {
                    var itemPaymentOptions = getPaymentOptionsForBundle(item['bundle'], true);
                    if (paymentOptions == null) {
                        paymentOptions = angular.copy(itemPaymentOptions);
                    } else {

                        for (var i = paymentOptions.length -1; i >= 0; i--) {
                            var paymentOption = paymentOptions[i];
                            var found = itemPaymentOptions.some(function(itemPaymentOption) {
                                return itemPaymentOption['code'] == paymentOption['code'];
                            });
                            if (!found) {
                                paymentOptions.splice(i, 1);
                            }
                        }
                    }

                    return paymentOptions;
                }

                function getPaymentOptionsForBundle(bundle, fallback) {
                    var paymentOptions = null;

                    if (bundle['sales_channels'] != undefined && bundle['sales_channels'].length > 0) {
                        paymentOptions = bundle['sales_channels'][0]['payment_options'];
                    } else if (bundle['sales_channel'] != undefined) {
                        paymentOptions = bundle['sales_channel']['payment_options'];
                    }

                    if (paymentOptions == null) {
                        if (fallback == true) {
                            paymentOptions = angular.copy(scope.defaultPaymentOptions);
                        } else {
                            paymentOptions = [];
                        }
                    }

                    return paymentOptions;
                }

                /*****************************************
                 * Defaults
                 *****************************************/

                // Get default invoice email and invoice type from customer billing or customer
                var invoiceEmail = undefined,
                    invoiceType = 'e-mail',
                    billingType = 'sepa',
                    iban = undefined,
                    customerInformation = scope.data.customer_billing_information || scope.data.customer_information;

                if (customerInformation) {
                    invoiceEmail = customerInformation.email || invoiceEmail;
                    invoiceType = customerInformation.invoice_type || invoiceType;
                    billingType = customerInformation.allow_standing_order ? 'sepa-active' : billingType;
                    iban = customerInformation.iban || iban;
                }

                var defaults = {
                    billing_type: billingType,
                    payment_mode: 'recurring',
                    invoice_type: invoiceType,
                    invoice_information_email: invoiceEmail,
                    iban: iban
                };

                /*****************************************
                 * Validation
                 *****************************************/
                scope.hasValidationErrors = function() {
                    return scope.billingForm.$submitted && !scope.billingForm.$valid;
                };

                scope.getValidationClass = function(field) {
                    if(scope['billingForm'].$submitted && scope['billingForm'][field].$invalid) {
                        return 'forms__label--error';
                    }

                    return '';
                };

                scope.isPaymentOptionAvailable = function(code) {
                    return scope.paymentOptions.filter(function(paymentOption) {
                        return paymentOption['code'] === code;
                    }).length > 0;
                };

                scope.isCustomer = function() {
                    var customerInformation = scope.data.customer_billing_information
                        || scope.data.customer_information;
                    return customerInformation.id != undefined;
                };

                scope.hasCustomerSepaActive = function() {
                    var customerInformation = scope.data.customer_billing_information
                        || scope.data.customer_information;
                    return customerInformation.allow_standing_order;
                };

                /*****************************************
                 * Actions
                 *****************************************/
                scope.actions.validate = function () {
                    scope.billingForm.$setSubmitted();

                    return (scope.billingForm.$valid === true);
                };

                /*****************************************
                 * Init
                 *****************************************/
                function init() {
                    scope.paymentOptions = [];
                    scope.defaultPaymentOptions = scope.info['default_payment_options'];
                    scope.definition = grabDefinition(scope.info, scope.data);
                    scope.prepareData();
                    // scope.data.billing = prepareBundleInformation(scope.definition, scope.data);
                    scope.data.billing = scope.data.billing || angular.copy(defaults);
                }

                init();
            }
        };
    })

    .service('BillingSummaryService', function(Translator, extractNestedValue) {

        return {
            getSummary: function(info, data, parameters) {

                var summary = [];

                /*****************************************
                 * Billing Type
                 *****************************************/

                var billingTypeData = null;
                switch (extractNestedValue(data, 'billing.billing_type')) {

                    case 'bank-transfer':
                        billingTypeData = [{
                            'identifier': 'billing.billing_type',
                            'type': 'value-only',
                            'value': Translator.translate('forms.billing.billing_type-bank_transfer')
                        }];
                        break;

                    case 'sepa':

                        var paymentModeValues = {
                            'single': Translator.translate('forms.billing.payment_mode-single'),
                            'recurring': Translator.translate('forms.billing.payment_mode-recurring')
                        };

                        billingTypeData = [
                            {
                                'identifier': 'billing.billing_type',
                                'type': 'value-only',
                                'value': Translator.translate('forms.billing.billing_type-sepa')
                            },
                            {
                                'identifier': 'billing.iban',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'billing.swift',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'billing.bank',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'billing.office',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'billing.payment_mode',
                                'type': 'key-value',
                                'value': paymentModeValues[data.billing.payment_mode]
                            }
                        ];
                        break;

                    case 'sepa-active':
                        billingTypeData = [{
                            'identifier': 'billing.billing_type',
                            'type': 'value-only',
                            'value': Translator.translate('forms.billing.billing_type-sepa_active')
                        }];
                        break;
                }

                summary.push({
                    'section': Translator.translate('forms.billing.billing_type'),
                    'data': billingTypeData
                });

                /*****************************************
                 * Invoice Type
                 *****************************************/

                var invoiceTypeData = null;
                switch (extractNestedValue(data, 'billing.invoice_type')) {

                    case 'e-mail':
                        invoiceTypeData = [{
                            'identifier': ['billing.invoice_type', 'billing.invoice_information_email'],
                            'type': 'key-value',
                            'label': Translator.translate('forms.billing.invoice_type-email'),
                            'value': extractNestedValue(data, 'billing.invoice_information_email')
                        }];
                        break;

                    case 'post':
                        invoiceTypeData = [{
                            'identifier': 'billing.invoice_type',
                            'type': 'value-only',
                            'value': Translator.translate('forms.billing.invoice_type-mail')
                        }];
                       break;
                }

                summary.push({
                    'section': Translator.translate('forms.billing.invoice_type'),
                    'data': invoiceTypeData
                });


                return summary;
            }
        };
    });