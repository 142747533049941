'use strict';

angular.module('forms.modules.forms',
    [
        'forms.modules.forms.bundle',
        'forms.modules.forms.virtual-bundle',
        'forms.helper',
        'rol.form',
        'rol.translation',
        'forms.customer-service'
    ]
)

    .directive('forms', function ($rootScope, $timeout, grabDefinition, prepareBundleInformation, prepareDataForForm, cleanDataForForm, DependenciesValidator, Translator) {
        return {
            restrict: 'E',
            scope: {
                'actions': '=',
                'parameters': '=',
                'info': '=',
                'data': '=',

                'startLoading': '=',
                'stopLoading': '='
            },
            templateUrl: 'modules/forms/forms.tpl.html',

            link: function (scope) {

                scope.formsFilters = {};

                /*****************************************
                 * Preparation
                 *****************************************/
                scope.prepareData = function () {
                    var definition = scope.definition;
                    var data = scope.data['forms'];

                    if (typeof definition['filters'] === 'object') {
                        prepareFilters(definition, data);
                    }

                    if (data['object_type'] === 'bundle') {
                        prepareDataForBundle(definition, data);
                    } else if (data['object_type'] === 'virtual-bundle') {
                        prepareDataForVirtualBundle(definition, data);
                    }

                    $rootScope.$broadcast('update-bundles');
                };

                function prepareFilters(definition, data) {
                    if (typeof data['filters'] !== 'object') {
                        data['filters'] = {};
                    }

                    for (var id in definition['filters']) {
                        if (!definition['filters'].hasOwnProperty(id)) continue;

                        var filterConfiguration = definition['filters'][id];
                        var filter = scope.getFilter(filterConfiguration);

                        data['filters'][filter.id] = prepareDataForForm(filter['form'], data['filters'][filter.id], scope.data);
                    }
                }

                function prepareDataForVirtualBundle(definition, data) {
                    if (typeof data['items'] !== 'object') {
                        data['items'] = {};
                    }

                    if (typeof definition['form'] === 'object' && definition['form'] !== null) {
                        data['forms'] = prepareDataForForm(definition['form'], data['forms'], scope.data);
                    } else {
                        delete data['forms'];
                    }

                    for (var i = 0; i < definition['virtual_bundle_items'].length; i++) {
                        prepareDataForVirtualBundleItem(definition['virtual_bundle_items'][i], data);
                    }

                    for (var virtualBundleItemId in data['items']) {
                        if (!data['items'].hasOwnProperty(virtualBundleItemId)) continue;

                        if (!containsVirtualBundleItem(definition, virtualBundleItemId)) {
                            delete data['items'][virtualBundleItemId];
                        }
                    }
                }

                function containsVirtualBundleItem(definition, virtualBundleItemId) {
                    for (var i = 0; i < definition['virtual_bundle_items'].length; i++) {
                        if (definition['virtual_bundle_items'][i]['id'] == virtualBundleItemId) {
                            return true;
                        }
                    }

                    return false;
                }

                function prepareDataForVirtualBundleItem(definition, data) {
                    var virtualBundleItemId = definition['id'];
                    var virtualBundleItemItems = definition['items'];

                    if (typeof data['items'][virtualBundleItemId] !== 'object' || data['items'][virtualBundleItemId] === null) {
                        data['items'][virtualBundleItemId] = {};
                    }

                    data['items'][virtualBundleItemId]['object_id'] = virtualBundleItemId;
                    data['items'][virtualBundleItemId]['object_type'] = 'virtual-bundle-item';

                    if (virtualBundleItemItems.length === 1) {
                        // Single
                        prepareDataForVirtualBundleSingleItem(definition, data);
                    } else {
                        // Choice
                        prepareDataForVirtualBundleChoiceItem(definition, data);
                    }
                }

                function prepareDataForVirtualBundleSingleItem(definition, data) {
                    var virtualBundleItemId = definition['id'];
                    var virtualBundleItemRequired = definition['required'];
                    var virtualBundleItemItems = definition['items'];
                    var virtualBundleItemData = data['items'][virtualBundleItemId];

                    if (isVirtualBundleItemDataDefined(data['items'][virtualBundleItemId])) {
                        if (virtualBundleItemRequired) {
                            if (isVirtualBundleItemDataSelected(virtualBundleItemItems, virtualBundleItemData)) {
                                virtualBundleItemData['bundle']['object_type'] = 'bundle';
                            } else {
                                setDefaultVirtualBundleItemItem(virtualBundleItemRequired, virtualBundleItemItems, virtualBundleItemData);
                            }
                        } else {
                            if (isVirtualBundleItemDataNotSelected(virtualBundleItemItems, virtualBundleItemData)) {
                                virtualBundleItemData['bundle'] = null;
                            } else if (isVirtualBundleItemDataSelected(virtualBundleItemItems, virtualBundleItemData)) {
                                virtualBundleItemData['bundle']['object_type'] = 'bundle';
                            } else {
                                setDefaultVirtualBundleItemItem(virtualBundleItemRequired, virtualBundleItemItems, virtualBundleItemData);
                            }
                        }
                    } else {
                        setDefaultVirtualBundleItemItem(virtualBundleItemRequired, virtualBundleItemItems, virtualBundleItemData);
                    }

                    if (virtualBundleItemData['bundle'] !== null) {
                        var selectedVirtualBundleItemItem = getSelectedVirtualBundleItemItem(virtualBundleItemItems, virtualBundleItemData['bundle']['object_id']);
                        prepareDataForBundle(selectedVirtualBundleItemItem['bundle'], virtualBundleItemData['bundle']);
                    }
                }

                function setDefaultVirtualBundleItemItem(virtualBundleItemRequired, virtualBundleItemItems, virtualBundleItemData) {
                    var defaultVirtualBundleItemItem = getDefaultVirtualBundleItemItem(virtualBundleItemItems);
                    var firstVirtualBundleItemItem = getFirstVirtualBundleItemItem(virtualBundleItemItems);

                    if (defaultVirtualBundleItemItem !== null) {
                        virtualBundleItemData['bundle'] = {
                            'object_id': defaultVirtualBundleItemItem['bundle']['id'],
                            'object_type': 'bundle'
                        };
                    } else if (virtualBundleItemRequired && firstVirtualBundleItemItem !== null) {
                        virtualBundleItemData['bundle'] = {
                            'object_id': firstVirtualBundleItemItem['bundle']['id'],
                            'object_type': 'bundle'
                        };
                    } else {
                        virtualBundleItemData['bundle'] = null;
                    }
                }

                function getDefaultVirtualBundleItemItem(virtualBundleItemItems) {
                    // Default Item
                    for (var i = 0; i < virtualBundleItemItems.length; i++) {
                        if (virtualBundleItemItems[i]['default'] === true) {
                            return virtualBundleItemItems[i];
                        }
                    }

                    return null;
                }

                function getFirstVirtualBundleItemItem(virtualBundleItemItems) {
                    // First Item
                    for (var j = 0; j < virtualBundleItemItems.length; j++) {
                        if (virtualBundleItemItems[j]['position'] === 0) {
                            return virtualBundleItemItems[j];
                        }
                    }

                    return null;
                }

                function getSelectedVirtualBundleItemItem(virtualBundleItemItems, bundleId) {
                    for (var i = 0; i < virtualBundleItemItems.length; i++) {
                        var virtualBundleItemItem = virtualBundleItemItems[i];

                        if (virtualBundleItemItem['bundle']['id'] === bundleId) {
                            return virtualBundleItemItem;
                        }
                    }

                    return null;
                }

                function prepareDataForVirtualBundleChoiceItem(definition, data) {
                    // Check: is bundle choice and required
                    // Check if data is given and valid bundle - prepare bundle data - otherwise set default choice bundle

                    // Check: is bundle choice and optional
                    // Check if data is given and valid bundle - prepare bundle data - otherwise set null
                    prepareDataForVirtualBundleSingleItem(definition, data);
                }

                function isVirtualBundleItemDataDefined(virtualBundleItemData) {
                    return typeof virtualBundleItemData['bundle'] === 'object';
                }

                function isVirtualBundleItemDataSelected(virtualBundleItemDefinition, virtualBundleItemData) {
                    if (typeof virtualBundleItemData['bundle'] !== 'object') {
                        return false;
                    }

                    for (var i = 0; i < virtualBundleItemDefinition.length; i++) {
                        if (virtualBundleItemDefinition[i]['bundle']['id'] === virtualBundleItemData['bundle']['object_id']) {
                            return true;
                        }
                    }

                    return false;
                }

                function isVirtualBundleItemDataNotSelected(virtualBundleItemDefinition, virtualBundleItemData) {
                    return (typeof virtualBundleItemData['bundle'] === 'object' && virtualBundleItemData['bundle'] === null);
                }

                function prepareDataForBundle(definition, data) {
                    if (typeof data['items'] !== 'object') {
                        data['items'] = {};
                    }

                    for (var i = 0; i < definition['bundle_items'].length; i++) {
                        prepareDataForBundleItem(definition['bundle_items'][i], data);
                    }
                }

                function prepareDataForBundleItem(definition, data) {
                    var bundleItemId = definition['id'];
                    var bundleItemQuantity = definition['quantity'];
                    var bundleItemForm = definition['form'];

                    // Check: does the bundle item have a form
                    if (bundleItemForm === null) {
                        return;
                    }

                    // Check: does the data object have a form for each bundle item
                    var forms = [];

                    if (typeof data['items'][bundleItemId] === 'object' && typeof data['items'][bundleItemId]['data'] !== 'undefined' && Object.prototype.toString.call(data['items'][bundleItemId]['data']) === '[object Array]') {
                        forms = data['items'][bundleItemId]['data'];
                    }

                    // Prepare: prepare the form objects with fields
                    for (var k = 0; k < bundleItemQuantity; k++) {
                        forms[k] = prepareDataForForm(bundleItemForm, forms[k], scope.data);
                    }

                    // Clean: clean the additional given forms
                    while (forms.length > bundleItemQuantity) {
                        forms.splice(bundleItemQuantity, 1);
                    }

                    data['items'][bundleItemId] = {
                        'object_id': bundleItemId,
                        'object_type': 'bundle-item',
                        'data': forms
                    };
                }

                /*****************************************
                 * Cleaning
                 *****************************************/
                scope.cleanData = function () {
                    var definition = scope.definition;
                    var cleanableData = scope.data['forms'];
                    var checkableData = angular.copy(scope.data['forms']);

                    if (typeof definition['filters'] === 'object') {
                        cleanFilters(definition, checkableData, cleanableData);
                    }

                    if (cleanableData['object_type'] === 'bundle') {
                        cleanDataForBundle(definition, checkableData, cleanableData);
                    }

                    if (cleanableData['object_type'] === 'virtual-bundle') {
                        cleanDataForVirtualBundle(definition, checkableData, cleanableData);
                    }
                };

                function cleanFilters(definition, checkableData, cleanableData) {
                    for (var id in definition['filters']) {
                        if (!definition['filters'].hasOwnProperty(id)) continue;

                        var filterConfiguration = definition['filters'][id];
                        var filter = scope.getFilter(filterConfiguration);

                        cleanDataForForm(filter['form'], checkableData['filters'][filter.id], cleanableData['filters'][filter.id]);
                    }
                }

                function cleanDataForVirtualBundle(definition, checkableData, cleanableData) {
                    if (typeof definition['form'] === 'object' && definition['form'] !== null) {
                        cleanDataForForm(definition['form'], checkableData['forms'], cleanableData['forms']);
                    }

                    for (var i = 0; i < definition['virtual_bundle_items'].length; i++) {
                        var virtualBundleItem = definition['virtual_bundle_items'][i];
                        var virtualBundleItemId = virtualBundleItem['id'];

                        // Check dependencies
                        if (!scope.hasValidDependencies(virtualBundleItem)) {
                            delete cleanableData['items'][virtualBundleItemId];
                        } else {
                            cleanDataForVirtualBundleItem(virtualBundleItem, checkableData, cleanableData);
                        }
                    }
                }

                function cleanDataForVirtualBundleItem(definition, checkableData, cleanableData) {
                    var virtualBundleItemId = definition['id'];
                    var virtualBundleItemItems = definition['items'];
                    var bundleCheckableData = checkableData['items'][virtualBundleItemId]['bundle'];
                    var bundleCleanableData = cleanableData['items'][virtualBundleItemId]['bundle'];

                    if (bundleCheckableData !== null) {
                        var selectedVirtualBundleItemItem = getSelectedVirtualBundleItemItem(virtualBundleItemItems, bundleCheckableData['object_id']);
                        cleanDataForBundle(selectedVirtualBundleItemItem['bundle'], bundleCheckableData, bundleCleanableData);
                    }
                }

                function cleanDataForBundle(definition, checkableData, cleanableData) {
                    if (typeof definition['activation_article'] === 'undefined' || definition['activation_article'] === null) {
                        cleanableData['activate'] = false;
                    } else {
                        cleanableData['activate'] = scope.hasValidDependencies(definition);
                    }

                    for (var i = 0; i < definition['bundle_items'].length; i++) {
                        cleanDataForBundleItem(definition['bundle_items'][i], checkableData, cleanableData);
                    }
                }

                function cleanDataForBundleItem(definition, checkableData, cleanableData) {
                    var bundleItemId = definition['id'];
                    var bundleItemQuantity = definition['quantity'];
                    var bundleItemForm = definition['form'];

                    // Check: does the bundle item have a form
                    if (bundleItemForm === null) {
                        return;
                    }

                    // Prepare: prepare the form objects with fields
                    var checkableForms = checkableData['items'][bundleItemId]['data'];
                    var cleanableForms = cleanableData['items'][bundleItemId]['data'];

                    for (var k = 0; k < bundleItemQuantity; k++) {
                        cleanDataForForm(bundleItemForm, checkableForms[k], cleanableForms[k]);
                    }
                }

                /*****************************************
                 * Dependencies
                 *****************************************/
                scope.hasValidDependencies = function (object) {
                    return DependenciesValidator.validate(object, scope.data['forms']);
                };

                var dependencyWatchers = {};

                function watchDependencies() {
                    if (scope.showVirtualBundleForm()) {
                        scope.definition.virtual_bundle_items.forEach(function (virtualBundleItem) {
                            if (typeof virtualBundleItem.additional_information === "object"
                                && typeof virtualBundleItem.additional_information.dependencies === "object") {
                                var dependency = virtualBundleItem.additional_information.dependencies;
                                addDependencyWatcher(dependency);
                            }
                        });
                    }
                }

                function addDependencyWatcher(dependency) {
                    switch (dependency.type) {
                        case "equal":
                        case "not-equal":
                            switch (dependency.section) {
                                case "virtual-bundle-form":
                                    addWatcher('data.forms.forms.' + dependency.identifier);
                                    break;
                                case "filter":
                                    addWatcher('data.forms.filters[' + dependency.subsection + "]." + dependency.identifier);
                                    break;
                            }
                            break;
                        case "and":
                        case "or":
                            dependency.checks.forEach(function (checkDependency) {
                                addDependencyWatcher(checkDependency);
                            });
                            break;
                    }
                }

                function addWatcher(identifier) {
                    if (typeof dependencyWatchers[identifier] !== "undefined") {
                        return;
                    }

                    dependencyWatchers[identifier] = scope.$watch(identifier, function (newValue, oldValue) {
                        if (oldValue !== newValue) {
                            $rootScope.$broadcast('update-bundles');
                        }
                    }, true);
                }

                function unwatchDependencies() {
                    for (var identifier in dependencyWatchers) {
                        if (!dependencyWatchers.hasOwnProperty(identifier)) continue;

                        dependencyWatchers[identifier]();

                        delete dependencyWatchers[identifier];
                    }
                }

                /*****************************************
                 * Presentation
                 *****************************************/
                scope.showBundleForm = function () {
                    return scope.data['forms']['object_type'] === 'bundle';
                };

                scope.showVirtualBundleForm = function () {
                    return scope.data['forms']['object_type'] === 'virtual-bundle';
                };

                scope.showDescription = function () {
                    return typeof scope.parameters.description === "boolean" && scope.parameters.description === true;
                };

                scope.showFilters = function () {
                    return typeof scope.parameters.filters === "boolean" && scope.parameters.filters === true;
                };

                scope.getDescriptionTitle = function () {
                    if (scope.data['forms']['object_type'] === 'bundle') {
                        return Translator.translateVariable('name', scope.definition['article']);
                    } else {
                        return Translator.translateVariable('name', scope.definition);
                    }
                };

                scope.getDescriptionText = function () {
                    return Translator.translateVariable('description', scope.definition['sales_channels'][0]);
                };

                /*****************************************
                 * Validation
                 *****************************************/
                scope.hasValidationErrors = function () {
                    if (!scope.formForms.$submitted) {
                        return false;
                    }

                    if (scope.formForms.$pending || !scope.formForms.$valid) {
                        return true;
                    }

                    for (var id in scope.definition['filters']) {
                        if (!scope.definition['filters'].hasOwnProperty(id)) continue;

                        var filter = scope.definition['filters'][id];

                        if (typeof scope['formsFilters'][filter.id] === 'undefined') {
                            continue;
                        }

                        if (scope['formsFilters'][filter.id].$pending || !scope['formsFilters'][filter.id].$valid) {
                            return true;
                        }
                    }

                    return false;
                };

                scope.hasFilterErrors = function () {
                    return scope.getFiltersWithErrors().length > 0;
                };

                scope.getFiltersWithErrors = function () {
                    var errors = [];

                    for (var id in scope.definition['filters']) {
                        if (!scope.definition['filters'].hasOwnProperty(id)) continue;

                        var filterConfiguration = scope.definition['filters'][id];
                        var allowedFilterItems = scope.definition['filters'][id]['allowed_filter_items'];
                        var filter = scope.getFilter(filterConfiguration);

                        if (typeof scope['formsFilters'][filterConfiguration.id] === 'undefined') {
                            continue;
                        }

                        if (!scope['formsFilters'][filterConfiguration.id].$submitted) {
                            continue;
                        }

                        var valid = false;

                        allowedFilterItems.forEach(function (item) {
                            if (scope.data['forms']['filters'][filter.id][filter['field_identifier']] === item['value']) {
                                valid = true;
                            }
                        });

                        if (valid === false) {
                            errors.push(filterConfiguration);
                        }
                    }

                    return errors;
                };

                function submitForms() {
                    scope.formForms.$setSubmitted();

                    if (!scope.showFilters()) {
                        return;
                    }

                    if (typeof scope.definition['filters'] !== 'object') {
                        return;
                    }

                    for (var id in scope.definition['filters']) {
                        if (!scope.definition['filters'].hasOwnProperty(id)) continue;

                        var filter = scope.definition['filters'][id];

                        scope['formsFilters'][filter.id].$setSubmitted();
                    }
                }

                var pendingFormWatchers = [];

                function watchPendingForms() {
                    $timeout(function () {
                        var watcher = scope.$watch(function () {
                            return scope.formForms.$pending;
                        }, function () {
                            validatePendingForms();
                        });

                        pendingFormWatchers.push(watcher);

                        if (scope.showFilters() && typeof scope.definition['filters'] === 'object') {
                            for (var id in scope.definition['filters']) {
                                if (!scope.definition['filters'].hasOwnProperty(id)) continue;

                                var filter = scope.definition['filters'][id];

                                scope.$watch((function (filter) {
                                    return function () {
                                        return scope.formsFilters[filter.id].$pending;
                                    }
                                })(filter), function () {
                                    validatePendingForms();
                                });
                            }
                        }
                    }, 0);
                }

                function unwatchPendingForms() {
                    while (pendingFormWatchers.length > 0) {
                        var watcher = pendingFormWatchers.pop();

                        watcher();
                    }
                }

                function validatePendingForms() {
                    var pending = false;

                    if (typeof scope.formForms.$pending === 'object') {
                        pending = true;
                    }

                    if (scope.showFilters() && typeof scope.definition['filters'] === 'object') {
                        for (var id in scope.definition['filters']) {
                            if (!scope.definition['filters'].hasOwnProperty(id)) continue;

                            var filter = scope.definition['filters'][id];

                            if (scope.formsFilters[filter.id].$pending === 'object') {
                                pending = true;
                            }
                        }
                    }

                    if (pending) {
                        scope.startLoading();
                    } else {
                        scope.stopLoading();
                    }
                }

                /*****************************************
                 * Helper
                 *****************************************/
                scope.getFilter = function (filterConfiguration) {
                    if (typeof filterConfiguration['filter'] === 'object') {
                        return filterConfiguration['filter'];
                    } else {
                        return scope.info['filters'][filterConfiguration.filter_id];
                    }
                };

                function restrictFilters() {
                    if (!scope.showFilters() || typeof scope.definition['filters'] !== 'object') {
                        return;
                    }

                    scope.definition['filters'].forEach(function (filter) {
                        var identifier = filter['filter']['field_identifier'];
                        var values = filter['allowed_filter_items'].map(function (item) {
                            return item['value'];
                        });

                        filter['filter']['form']['form_fields'].forEach(function (field) {
                            if (field['identifier'] === identifier) {
                                if (typeof field['additional_information'] === 'undefined') {
                                    field['additional_information'] = {};
                                }

                                field['additional_information']['validator_values'] = values;
                            }
                        });
                    });
                }

                /*****************************************
                 * Actions
                 *****************************************/
                scope.actions.validate = function () {
                    submitForms();

                    var errorsValidation = scope.hasValidationErrors();
                    var errorsFilters = false;

                    if (scope.showFilters()) {
                        errorsFilters = scope.hasFilterErrors();
                    }

                    return !(errorsValidation || errorsFilters);
                };

                scope.actions.clean = function () {
                    unwatchDependencies();
                    unwatchPendingForms();

                    scope.cleanData();

                    return true;
                };

                /*****************************************
                 * Init
                 *****************************************/
                function init() {
                    scope.definition = grabDefinition(scope.info, scope.data);

                    restrictFilters();
                    scope.prepareData();
                    watchPendingForms();
                    watchDependencies();

                    scope.bundleInformation = prepareBundleInformation(scope.definition, scope.data, scope.parameters);
                }

                init();
            }
        };
    })

    .service('FormsSummaryService', function (Translator, grabDefinition, DependenciesValidator, prepareBundleInformation, summarizeDataForForm, $filter) {

        function getArticlePrice(article) {
            var priceString = '';
            var price = null;

            if (article && article['price']) {
                price = article['price'];

                if (price.show_without_vat) {
                    priceString = $filter('currency')(price.without_vat) + ' (' + article['periodicity'] + ' / ' + Translator.translate('forms.forms.plus-vat') + ')';
                } else {
                    priceString = $filter('currency')(price.with_vat) + ' (' + article['periodicity'] + ' / ' + $filter('currency')(price.without_vat) + ' ' + Translator.translate('forms.forms.plus-vat') + ')';
                }
            }

            return priceString;
        }

        function getContractPeriodicity(contractPeriodicity) {
            if (contractPeriodicity) {
                if (contractPeriodicity.contract_periodicity === 'yearly' || contractPeriodicity.contract_periodicity === 'monthly') {
                    return Translator.translate('forms.forms.bundle-price.contract-period') + ': ' + contractPeriodicity.contract_periodicity_quantity + ' ' + Translator.translate('forms.forms.price.' + contractPeriodicity.contract_periodicity + '-' + (contractPeriodicity.contract_periodicity_quantity === 1 ? 'singular' : 'plural'));
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }

        function buildBundleSummary(bundleDefinition, identifierPath, fullData, bundleData, parameters) {

            var sectionData = [];

            var bundleId = bundleDefinition['id'];
            var bundleInformation = prepareBundleInformation(bundleDefinition, fullData, parameters);
            // Article
            var articlePrice = getArticlePrice(bundleInformation[bundleId]['article']);
            var contractPeriodicity = getContractPeriodicity(bundleDefinition);

            sectionData.push({
                'type': 'key-value',
                'style': 'price',
                'label': Translator.translate('forms.forms.price'),
                'value': articlePrice,

            });

            // Activation Article
            if (bundleDefinition['activation_article'] && DependenciesValidator.validate(bundleDefinition, fullData['forms'])) {
                var activationArticlePrice = getArticlePrice(bundleInformation[bundleId]['activation_article']);

                sectionData.push({
                    'type': 'key-value',
                    'style': 'price',
                    'label': Translator.translateVariable('name', bundleDefinition['activation_article']),
                    'value': activationArticlePrice
                });
            }

            // Deactivation Article
            if (bundleDefinition['deactivation_article']) {
                var deactivationArticlePrice = getArticlePrice(bundleInformation[bundleId]['deactivation_article']);

                sectionData.push({
                    'type': 'key-value',
                    'style': 'price',
                    'label': Translator.translateVariable('name', bundleDefinition['deactivation_article']),
                    'value': deactivationArticlePrice
                });
            }
            // Bundle Items
            bundleDefinition['bundle_items'].forEach(function (bundleItem) {

                if (!bundleItem.visible) {
                    return;
                }

                var bundleItemId = bundleItem['id'];
                var bundleItemQuantity = bundleItem['quantity'];
                var bundleItemForm = bundleItem['form'];

                sectionData.push({
                    'type': 'value-only',
                    'style': 'article-name',
                    'value': Translator.translateVariable('name', bundleItem['article']),

                });

                if (!bundleItemForm) {
                    return;
                }

                for (var j = 0; j < bundleItemQuantity; j++) {
                    var formPath = identifierPath.concat(['items', bundleItemId, 'data', j]);
                    var formData = bundleData['items'][bundleItemId]['data'][j];

                    summarizeDataForForm(bundleItemForm, formData, sectionData, formPath, 'sub-item');

                    // Separator
                    if (j < bundleItemQuantity - 1) {
                        sectionData.push({
                            'type': 'separator'
                        });
                    }
                }
            });

            return {
                'section': Translator.translateVariable('name', bundleDefinition.article),
                'data': sectionData,
                'contract_periodicity': contractPeriodicity
            };
        }

        function buildSummary(summary, definition, identifierPath, fullData, scopedData, parameters) {
            if (scopedData) {
                switch (scopedData['object_type']) {

                    case 'virtual-bundle':
                        var virtualBundleForm = definition['form'];

                        if (virtualBundleForm) {
                            var virtualBundleSectionData = [];
                            var virtualBundleFormData = scopedData['forms'];
                            var virtualBundleFormPath = identifierPath.concat(['forms']);

                            summarizeDataForForm(virtualBundleForm, virtualBundleFormData, virtualBundleSectionData, virtualBundleFormPath, 'virtual-bundle-form');

                            summary.push({
                                'section': Translator.translateVariable('name', definition),
                                'data': virtualBundleSectionData
                            });
                        }

                        definition['virtual_bundle_items'].forEach(function (virtualBundleItem) {
                            var virtualBundleItemId = virtualBundleItem['id'];
                            var virtualBundleItemData = scopedData['items'][virtualBundleItemId];
                            var virtualBundleItemPath = identifierPath.concat(['items', virtualBundleItemId]);

                            if (!DependenciesValidator.validate(virtualBundleItem, fullData['forms'])) {
                                return;
                            }

                            buildSummary(summary, virtualBundleItem, virtualBundleItemPath, fullData, virtualBundleItemData);
                        });
                        break;

                    case 'virtual-bundle-item':
                        definition['items'].forEach(function (virtualBundleItemItem) {
                            var bundle = virtualBundleItemItem['bundle'];
                            var bundleData = scopedData['bundle'];
                            var bundlePath = identifierPath.concat(['bundle']);

                            if (bundleData && bundleData['object_id'] == bundle['id']) {
                                buildSummary(summary, bundle, bundlePath, fullData, bundleData, parameters);
                            }
                        });
                        break;

                    case 'bundle':
                        summary.push(
                            buildBundleSummary(definition, identifierPath, fullData, scopedData, parameters)
                        );
                        break;
                }
            }
        }

        function getFilter(info, filterConfiguration) {
            if (typeof filterConfiguration['filter'] === 'object') {
                return filterConfiguration['filter'];
            } else {
                return scope.info['filters'][filterConfiguration.filter_id];
            }
        }

        return {
            getSummary: function (info, data, parameters) {
                var definition = grabDefinition(info, data);
                var summary = [];

                if (typeof parameters.filters === "boolean" && parameters.filters === true) {
                    var filters = definition['filters'];

                    for (var i = 0; i < filters.length; i++) {
                        var filterSectionData = [];

                        var filterConfiguration = filters[i];
                        var filter = getFilter(info, filterConfiguration);

                        var filterForm = filters[i]['filter']['form'];
                        var filterData = data['forms']['filters'][filter.id];
                        var filterFormPath = ['forms', 'filters', filter.id];

                        summarizeDataForForm(filterForm, filterData, filterSectionData, filterFormPath, '');

                        summary.push({
                            'section': Translator.translateVariable('name', filters[i]['filter']),
                            'data': filterSectionData
                        });
                    }
                }

                buildSummary(summary, definition, ['forms'], data, data['forms'], parameters);

                return summary;
            }
        };
    })

    .factory('prepareBundleInformation', function ($filter, Translator, CustomerService) {

        var bundleInformation = {};

        function prepareBundleInformationForBundle(bundle, data, forceWithoutVat) {
            if (!bundleInformation[bundle.id]) {
                bundleInformation[bundle.id] = {
                    'article': getFormattedPriceInformation(bundle, data, bundle.article, forceWithoutVat),
                    'activation_article': getFormattedPriceInformation(bundle, data, bundle.activation_article, forceWithoutVat),
                    'deactivation_article': getFormattedPriceInformation(bundle, data, bundle.deactivation_article, forceWithoutVat),
                    'prices': bundle.prices,
                };
            }
        }

        function getFormattedPriceInformation(bundle, data, article, forceWithoutVat) {
            var price = null;
            var periodicity = null;

            var customerId = CustomerService.getCustomerId(data);
            if (article && article.prices) {
                for (var i = 0; i < article.prices.length; i++) {
                    if (customerId === article.prices[i].customer_id) {
                        price = article.prices[i];
                        break;
                    }
                    if (article.prices[i].customer_id === null) {
                        price = article.prices[i];
                        if (!customerId) {
                            break;
                        }
                    }
                }
            }

            if (price === null) {
                return {
                    'price': price,
                    'periodicity': periodicity
                }
            }

            var expirationPeriodicity = price.definition.expiration_periodicity;
            var expirationPeriodicityQuantity = price.definition.expiration_periodicity_quantity;

            if (expirationPeriodicity) {

                var periodicities = {
                    'accompanying document': Translator.translate('forms.forms.price.single'),
                    'no one': Translator.translate('forms.forms.price.single'),
                    'daily': Translator.translate('forms.forms.price.daily'),
                    'weekly': Translator.translate('forms.forms.price.weekly'),
                    'monthly': Translator.translate('forms.forms.price.monthly'),
                    'yearly': Translator.translate('forms.forms.price.yearly')
                };

                if (expirationPeriodicityQuantity > 1) {
                    periodicities = {
                        'daily': Translator.translate('forms.forms.price.days'),
                        'weekly': Translator.translate('forms.forms.price.weeks'),
                        'monthly': Translator.translate('forms.forms.price.months'),
                        'yearly': Translator.translate('forms.forms.price.years')
                    };
                    periodicity = Translator.translate('forms.forms.price.every')
                        + ' ' + expirationPeriodicityQuantity + ' ' + periodicities[expirationPeriodicity];
                } else {
                    periodicity = periodicities[expirationPeriodicity];
                }
            } else {
                periodicity = Translator.translate('forms.forms.price.single');
            }

            price.show_without_vat = forceWithoutVat || CustomerService.isCommercial(data, bundle);

            return {
                'price': price,
                'periodicity': periodicity
            }
        }

        return function (definition, data, parameters) {

            var forceWithoutVat = typeof parameters !== 'undefined' && parameters !== null
                && typeof parameters.forceWithoutVat !== 'undefined' && parameters.forceWithoutVat === true;

            if (definition.article) {
                prepareBundleInformationForBundle(definition, data, forceWithoutVat);
            }

            if (definition.virtual_bundle_items) {
                for (var i = 0; i < definition.virtual_bundle_items.length; i++) {
                    var virtualBundleItem = definition.virtual_bundle_items[i];

                    for (var j = 0; j < virtualBundleItem.items.length; j++) {
                        var bundle = virtualBundleItem.items[j].bundle;
                        prepareBundleInformationForBundle(bundle, data, forceWithoutVat);
                    }
                }
            }

            return bundleInformation;
        };
    });
