'use strict';

angular.module('rol.translation',
    [
        'user',
        'gettext'
    ]
    )

    .run(function(language, gettextCatalog) {
        gettextCatalog.setCurrentLanguage(language);
    })

    .factory('Translator', function(language, gettextCatalog) {
        return {
            'translate': function(identifier) {
                return gettextCatalog.getString(identifier);
            },
            'translateVariable': function(variable, object) {
                if (typeof object === 'undefined' || object === null) {
                    return '';
                }

                variable = (variable || '') + '_' + language;

                if(variable in object) {
                    return object[variable];
                }

                return '';
            }
        };
    })

    .filter('translateVariable', function(Translator) {
        return function(variable, object) {
            return Translator.translateVariable(variable, object);
        };
    });
