'use strict';

angular.module('rol.form.dependency',
    [
    ]
    )

    .factory('DependenciesValidator', function() {
        function checkDependencies(dependencies, data) {
            if(typeof data === 'undefined') {
                return false;
            }

            if(dependencies['type'] === 'equal') {
                return checkEqualDependency(dependencies, data);
            }

            if(dependencies['type'] === 'not-equal') {
                return checkNotEqualDependency(dependencies, data);
            }

            if(dependencies['type'] === 'and') {
                return checkAndDependencies(dependencies, data);
            }

            if(dependencies['type'] === 'or') {
                return checkOrDependencies(dependencies, data);
            }

            return false;
        }

        function checkEqualDependency(dependency, data) {
            var expectedValue = dependency['value'];
            var currentValue = getValue(dependency, data);

            if(typeof currentValue === 'undefined') {
                return true;
            }

            return currentValue === expectedValue;
        }

        function checkNotEqualDependency(dependency, data) {
            var expectedValue = dependency['value'];
            var currentValue = getValue(dependency, data);

            if(typeof currentValue === 'undefined') {
                return true;
            }

            return currentValue !== expectedValue;
        }

        function checkAndDependencies(dependencies, data) {
            if(dependencies['checks'].length === 0) {
                return false;
            }

            for(var i = 0; i < dependencies['checks'].length; i++) {
                var check = dependencies['checks'][i];

                if(! checkDependencies(check, data)) {
                    return false;
                }
            }

            return true;
        }

        function checkOrDependencies(dependencies, data) {
            if(dependencies['checks'].length === 0) {
                return false;
            }

            for(var i = 0; i < dependencies['checks'].length; i++) {
                var check = dependencies['checks'][i];

                if(checkDependencies(check, data)) {
                    return true;
                }
            }

            return false;
        }

        function getValue(source, data) {
            var section = source['section'];
            var identifier = source['identifier'];

            switch(section) {
                case 'form':
                    return data[identifier];
                    break;
                case 'filter':
                    var subSection = source['subsection'];

                    if(typeof data['filters'] === 'undefined') return;
                    if(typeof data['filters'][subSection] === 'undefined') return;

                    return data['filters'][subSection][identifier];
                    break;
                case 'virtual-bundle-form':
                    if(typeof data['forms'] === 'undefined') return;

                    return data['forms'][identifier];
                    break;
                case 'virtual-bundle-item':
                    if(typeof data['items'] === 'undefined') return;
                    if(typeof data['items'][identifier] === 'undefined') return;
                    if(typeof data['items'][identifier]['bundle'] === 'undefined') return;

                    if(data['items'][identifier]['bundle'] === null) {
                        return null;
                    } else {
                        return data['items'][identifier]['bundle']['object_id'];
                    }
                    break;
                default:
                    return;
            }
        }

        return {
            'validate': function(object, data) {
                var additionalInformation = object['additional_information'];

                if(typeof additionalInformation !== 'object' || additionalInformation === null || (! ('dependencies' in additionalInformation))) {
                    return true;
                }

                return checkDependencies(additionalInformation['dependencies'], data);
            }
        };
    });
