'use strict';

angular.module('forms.modules.order',
    [
        'forms.helper',
        'rol.form.validation',
        'rol.translation'
    ]
    )

    .directive('order', function($filter) {
        return {
            restrict: 'E',
            scope: {
                'actions': '=',
                'parameters': '=',
                'info': '=',
                'data': '='
            },
            templateUrl: 'modules/order/order.tpl.html',
            link: function (scope) {

                /*****************************************
                 * Hide sections
                 *****************************************/
                scope.hideSection = function(section) {
                    return scope.parameters.hideSections && scope.parameters.hideSections.indexOf(section) > -1;
                };

                /*****************************************
                 * Defaults
                 *****************************************/
                var defaults = {};

                if (!scope.hideSection('hardware_delivery')) {
                    defaults['hardware_delivery'] = 'pick-up';
                }

                /*****************************************
                 * Validation
                 *****************************************/
                scope.hasValidationErrors = function() {
                    return scope.orderForm.$submitted && !scope.orderForm.$valid;
                };


                scope.getValidationClass = function(field) {
                    if(scope['orderForm'].$submitted && scope['orderForm'][field].$invalid) {
                        return 'forms__label--error';
                    }

                    return '';
                };

                /*****************************************
                 * Actions
                 *****************************************/
                scope.actions.validate = function () {
                    scope.orderForm.$setSubmitted();

                    return (scope.orderForm.$valid === true);
                };

                /*****************************************
                 * Init
                 *****************************************/
                function init() {
                    if (typeof scope.data.order === 'undefined') {
                        scope.data.order = {};
                    }

                    if (typeof scope.data.order.hardware_delivery === 'undefined') {
                        scope.data.order.hardware_delivery = defaults.hardware_delivery;
                    }
                }

                init();
            }
        };
    })

    .service('OrderSummaryService', function(Translator, extractNestedValue) {
        return {
            getSummary: function(info, data, parameters) {

                var hardwareDeliveryValues = {
                    'forms.order.hardware_delivery.bank': Translator.translate('forms.order.hardware_delivery.bank'),
                    'forms.order.hardware_delivery.pick-up': Translator.translate('forms.order.hardware_delivery.pick-up'),
                    'forms.order.hardware_delivery.technical-service-call': Translator.translate('forms.order.hardware_delivery.technical-service-call')
                };

                return [
                    {
                        'section': Translator.translate('forms.order.order'),
                        'data': [
                            {
                                'identifier': 'order.bank',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'order.office',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'order.employee',
                                'type': 'key-value'
                            }
                        ]
                    },
                    {
                        'section': Translator.translate('forms.order.comment'),
                        'data': [
                            {
                                'identifier': 'order.comment',
                                'type': 'value-only'
                            }
                        ]
                    },
                    {
                        'section': Translator.translate('forms.order.hardware_delivery'),
                        'data': [
                            !extractNestedValue(data, 'order.hardware_delivery') ? null : {
                                'identifier': 'order.hardware_delivery',
                                'value': hardwareDeliveryValues['forms.order.hardware_delivery.'
                                + extractNestedValue(data, 'order.hardware_delivery')]
                            },
                            extractNestedValue(data, 'order.hardware_delivery') !== 'bank' ? null : {
                                'identifier': 'order.delivery_location',
                                'type': 'key-value',
                                'label': Translator.translate('forms.order.delivery_location'),
                                'value': extractNestedValue(data, 'order.delivery_location')
                            }
                        ]
                    }
                ];
            }
        };
    });