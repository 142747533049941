angular.module('rol.form.fields.email-limited-domain',
    [
        'rol.form.validation'
    ]
)

    .directive('emailLimitedDomain', function() {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                'domains': '='
            },
            templateUrl: 'fields/email-limited-domain.tpl.html',

            link: function (scope, element, attr, ngModelCtrl) {

                // Defaults
                scope.invalid = false;
                scope.account = '';
                scope.domain = '';

                // Model Controller
                ngModelCtrl.$render = function() {
                    splitAndSetEmail(ngModelCtrl.$viewValue);
                };

                scope.updateModel = function() {
                    if(typeof scope.account === 'undefined' || typeof scope.domain === 'undefined') {
                        ngModelCtrl.$setViewValue('');
                        return;
                    }

                    if (scope.account.trim() === '' || scope.domain.trim() === '') {
                        ngModelCtrl.$setViewValue('');
                        return;
                    }

                    ngModelCtrl.$setViewValue(scope.account + scope.domain);
                };

                scope.$watch(function() {
                    return ngModelCtrl.$invalid;
                }, function(invalid) {
                    scope.invalid = invalid;
                });

                // Helper
                scope.getValidationClass = function() {
                    if(scope.invalid === true) {
                        return 'ng-invalid';
                    }

                    return '';
                };

                function splitAndSetEmail(email) {
                    if (typeof email === 'undefined' || email === null || email.trim() === '') {
                        email = '';
                    }

                    var parts = email.split("@");
                    var result = {
                        'account': '',
                        'domain': ''
                    };

                    if(parts.length == 2) {
                        result['account'] = parts[0];
                        result['domain'] = '@' + parts[1];
                    } else if(scope.domains.length > 0) {
                        result['domain'] = scope.domains[0];
                    }

                    scope.account = result['account'];
                    scope.domain = result['domain'];
                }
            }
        }
    });
