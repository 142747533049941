'use strict';

angular.module('forms.wizard',
    [
        'forms.modules.customer',
        'forms.modules.forms',
        'forms.modules.billing',
        'forms.modules.summary',
        'forms.modules.signalization',
        'forms.modules.order',
        'forms.modules.service',
        'forms.modules.sepa',
        'forms.modules.avowal',
        'forms.components.usp',
        'rol.api',
        'rol.bootstrap-modal',
        'rol.translation'
    ]
    )

    .directive('wizard', function($rootScope, $compile, $location, $window, $timeout, Translator, ApiService, hasUSP) {
        return {
            restrict: 'E',
            scope: {
                'config': '=',
                'modules': '=',
                'info': '=',
                'data': '=',
                'templateUrl': '=?'
            },
            templateUrl: function(elem, attr) {
                return typeof attr.templateUrl !== 'undefined'
                    ? attr.templateUrl
                    : 'wizard/wizard.tpl.html';
            },
            link: function(scope) {

                /******************************************
                 * Events
                 ******************************************/
                scope.$on('wizard.init', function(e) {
                    init();
                    replaceUrlState();
                });

                scope.$on('wizard.start', function(e) {
                    start();
                });

                scope.$on('wizard.stop', function(e) {
                    stop();
                });

                scope.$on('wizard.previous', function(e) {
                    // scope.previous();
                });

                scope.$on('wizard.next', function(e) {
                    // scope.next();
                });


                /******************************************
                 * States
                 ******************************************/
                function init() {
                    setUrlState('none');
                }

                function start() {
                    // Request Parameters
                    ApiService.clearGlobalRequestParameters();

                    if(typeof scope.info['request_parameters'] === 'object') {
                        var requestParameters = scope.info['request_parameters'];

                        for (var key in requestParameters) {
                            if (!requestParameters.hasOwnProperty(key)) continue;

                            ApiService.setGlobalRequestParameter(key, requestParameters[key]);
                        }
                    }

                    // Action Buttons
                    scope.modules.forEach(function(module, i) {
                        if (i === 0) return;

                        if (! scope.config.actionButtonsManager.hasVisibility(module.name, 'previous')) {
                            scope.config.actionButtonsManager.showButtonForModule(module.name, 'previous');
                        }

                        scope.config.actionButtonsManager.setModuleAction(module.name, 'previous', function(params) {
                            scope.setCurrentModule(scope.current_index - 1);

                            return true;
                        });
                    });

                    scope.modules.forEach(function(module, i) {
                        if (i === scope.modules.length - 1) return;

                        if (! scope.config.actionButtonsManager.hasVisibility(module.name, 'next')) {
                            scope.config.actionButtonsManager.showButtonForModule(module.name, 'next');
                        }

                        scope.config.actionButtonsManager.setModuleAction(module.name, 'next', function (params) {
                            scope.setCurrentModule(scope.current_index + 1);

                            // Skip - On Wizard Next
                            skip();

                            return true;
                        });
                    });

                    scope.modules.forEach(function(module, i) {
                        scope.config.actionButtonsManager.setModuleAction(module.name, 'jump', function (params) {
                            var index = scope.getModuleIndex(params.name);

                            if (index === null) {
                                return false;
                            }

                            scope.setCurrentModule(index);

                            return true;
                        });
                    });

                    if (typeof scope.config.module === 'string') {
                        var current = scope.getModuleIndex(scope.config.module);

                        if (current === null) {
                            current = 0;
                        }

                        scope.setCurrentModule(current);
                    } else {
                        scope.setCurrentModule(0);
                    }

                    // Skip - On Wizard Start
                    skip();

                    setUrlState('active');

                    $timeout(function() {
                        $rootScope.$broadcast('wizard.started');
                    }, 0);
                }

                function stop() {
                    ApiService.clearGlobalRequestParameters();

                    setUrlState('done');
                    replaceUrlState();

                    $timeout(function() {
                        $rootScope.$broadcast('wizard.stopped');
                    }, 0);
                }

                function setUrlState(state) {
                    var search = $location.search();

                    search['wizard'] = state;

                    $location.search(search);
                }

                function replaceUrlState() {
                    $location.replace();
                }

                function skip() {
                    var parameters = scope.moduleParameters(scope.current_module.name);

                    if (typeof parameters['skip'] !== 'undefined' && parameters['skip'] === true) {
                        $timeout(function() {
                            scope.config.actionButtonsManager.call('next');
                            parameters['skip'] = false;
                        }, 0);
                    }
                }

                /******************************************
                 * Module
                 ******************************************/
                var moduleScope = null;

                scope.setCurrentModule = function(nameOrIndex) {

                    // Remove old module
                    if (moduleScope !== null) {
                        moduleScope.$destroy();
                    }
                    var angularElement = angular.element(document.getElementById('wizard_modules'));
                    angularElement.empty();

                    // Select current module
                    var currentIndex = -1;
                    var currentModule = null;

                    if (typeof nameOrIndex === 'number') {
                        currentIndex = nameOrIndex;
                        currentModule = scope.modules[nameOrIndex];
                    } else {
                        for (var i = 0; i < scope.modules.length; i++)  {
                            if (scope.modules[i].name === nameOrIndex) {
                                currentIndex = i;
                                currentModule = scope.modules[i];
                            }
                        }
                    }

                    scope.current_index = currentIndex;
                    scope.current_module = currentModule;
                    scope.config.actionButtonsManager.setCurrentModule(currentModule.name);

                    // Add new module
                    if (scope.current_module !== null) {
                        scope.actions = {};

                        moduleScope = scope.$new();

                        // Module Element
                        var moduleElement = $compile(
                            '<' + scope.current_module.name + ' ' +
                            'ng-show="isCurrentModule(\'' + scope.current_module.name + '\')" ' +

                            'actions="actions"' +
                            'parameters="moduleParameters(\'' + scope.current_module.name + '\')" ' +
                            'info="info" ' +
                            'data="data" ' +

                            'call="call"' +
                            'start-loading="startLoading"' +
                            'stop-loading="stopLoading"' +

                            'module-callable="moduleCallable"' +
                            'module-parameters="moduleParameters"' +

                            'set-title="setTitle"' +
                            'set-sales-channel="setSalesChannel"' +
                            '></' + scope.current_module.name + '>')(moduleScope);

                        angularElement.append(moduleElement);

                        // Register
                        $timeout(function() {
                            if (typeof scope.actions === 'object' && typeof scope.actions.clean === 'function') {
                                scope.config.actionButtonsManager.setModulePreHook(scope.current_module.name, 'jump', 10, scope.actions.clean);
                                scope.config.actionButtonsManager.setModulePreHook(scope.current_module.name, 'previous', 10, scope.actions.clean);
                                scope.config.actionButtonsManager.setModulePreHook(scope.current_module.name, 'next', 10, scope.actions.clean);
                                scope.config.actionButtonsManager.setModulePreHook(scope.current_module.name, 'cancel', 10, scope.actions.clean);
                                scope.config.actionButtonsManager.setModulePreHook(scope.current_module.name, 'save', 10, scope.actions.clean);
                                scope.config.actionButtonsManager.setModulePreHook(scope.current_module.name, 'complete', 10, scope.actions.clean);
                            }

                            if (typeof scope.actions === 'object' && typeof scope.actions.validate === 'function') {
                                scope.config.actionButtonsManager.setModulePreHook(scope.current_module.name, 'next', 100, scope.actions.validate);
                                scope.config.actionButtonsManager.setModulePreHook(scope.current_module.name, 'complete', 100, scope.actions.validate);
                            }
                        }, 0);
                    }
                };

                scope.getFirstModule = function() {
                    return scope.modules[0];
                };

                scope.getLastModule = function() {
                    return scope.modules[scope.modules.length - 1];
                };

                scope.getModuleIndex = function(name) {
                    var index = null;

                    scope.modules.forEach(function(module, i) {
                        if (module.name === name) {
                            index = i;
                        }
                    });

                    return index;
                };

                scope.isCurrentModule = function(name) {
                    return scope.current_module && scope.current_module.name === name;
                };

                scope.getCurrentModule = function() {
                    return scope.current_module;
                };

                scope.getCurrentIndex = function() {
                    return scope.current_index;
                };

                /******************************************
                 * Dependencies
                 ******************************************/
                scope.call = function(button, params) {
                    scope.config.actionButtonsManager.call(button, params);
                };

                scope.startLoading = function() {
                    scope.config.actionButtonsManager.startLoading();
                };

                scope.stopLoading = function() {
                    scope.config.actionButtonsManager.stopLoading();
                };

                scope.moduleParameters = function(name) {
                    var i;

                    for(i = 0; i < scope.modules.length; i++)  {
                        var module = scope.modules[i];

                        if(module.name === name) {
                            return module.parameters;
                        }
                    }

                    return null;
                };

                scope.moduleCallable = function(name) {
                    var index = scope.getModuleIndex(name);

                    if (index === null) {
                        return false;
                    }

                    return (index <= scope.current_index);
                };


                /******************************************
                 * Title & Sales Channel
                 ******************************************/
                scope.getTitle = function() {
                    if (typeof scope.info['title'] === 'undefined') {
                        return undefined;
                    }

                    return scope.info['title'].get(scope.getCurrentIndex());
                };

                scope.setTitle = function(name) {
                    scope.info['title'].insert(scope.getCurrentIndex() + 1, name);
                };

                scope.getSalesChannel = function() {
                    if (typeof scope.info['sales_channel'] === 'undefined') {
                        return undefined;
                    }

                    var salesChannel = scope.info['sales_channel'].get(scope.getCurrentIndex());

                    // Helper function from forms.components.usp
                    return hasUSP(salesChannel) ? salesChannel : undefined;
                };

                scope.openUspUrl = function(salesChannel) {
                    $window.open(Translator.translateVariable('usp_file', salesChannel), '_blank');
                };

                scope.setSalesChannel = function(salesChannel) {
                    scope.info['sales_channel'].insert(scope.getCurrentIndex() + 1, salesChannel);
                };

                /******************************************
                 * Errors
                 ******************************************/
                scope.hasError = function() {
                    return typeof scope.config.error === 'string' && scope.config.error !== null;
                };

                scope.getError = function() {
                    if (! scope.hasError()) {
                        return '';
                    }

                    return scope.config.error;
                };

                /******************************************
                 * Initialization
                 ******************************************/
                init();
            }
        };
    })

    .run(['$rootScope', '$location', function ($rootScope, $location) {

        var initialized = false;
        var done = false;

        function getWizardState(url) {
            var parts = url.split('#');

            if(parts.length !== 2) {
                return 'none';
            }

            var location = parts[1];

            var regexp = /\/?.*wizard=([^&]*)($|&)/g;
            var match = regexp.exec(location);

            if(match === null) {
                return 'none';
            }

            var state = match[1];

            switch(state) {
                case 'active':
                    return 'active';

                case 'done':
                    return 'done';

                case 'none':
                default:
                    return 'none';
            }
        }

        $rootScope.$on('$locationChangeStart', function(event, next, current) {

            var currentWizardState = getWizardState(current);
            var nextWizardState = getWizardState(next);

            // Initialization
            if(!initialized && nextWizardState === 'none') {
                initialized = true;
                return;
            }

            // Redirect After Done
            if(currentWizardState === 'active' && nextWizardState === 'done') {
                done = true;

                $rootScope.$broadcast('wizard.init');
                
                return;
            }

            if(done && nextWizardState === 'none') {
                done = false;
                return;
            }

            // Prevent Wrong Steps
            if(currentWizardState === 'active' && nextWizardState === 'none') {
                $rootScope.$broadcast('wizard.previous');

                event.preventDefault();
                return;
            }

            if(currentWizardState === 'none' && nextWizardState === 'done') {
                event.preventDefault();
                return;
            }

            if(currentWizardState === 'done' && nextWizardState === 'active') {
                event.preventDefault();
                return;
            }
        });

    }]);
