'use strict';

angular.module('forms.modules.service',
    [
        'forms.helper',
        'rol.form',
        'rol.form.validation',
        'rol.translation'
    ]
)

    .directive('service', function(Translator, prepareDataForForm, cleanDataForForm) {
        return {
            restrict: 'E',
            scope: {
                'actions': '=',
                'parameters': '=',
                'info': '=',
                'data': '=',

                'call': '=',
                'setTitle': '=',
                'setSalesChannel': '='
            },
            templateUrl: 'modules/service/service.tpl.html',
            link: function (scope) {

                /*****************************************
                 * Type
                 *****************************************/
                scope.isContractService = function() {
                    return scope.parameters['type'] === 'contract';
                };

                scope.isTemplateService = function() {
                    return scope.parameters['type'] === 'template';
                };

                scope.choiceButtonNotSelected = function(item) {
                    return typeof scope.data['forms'] === 'object'
                        && typeof scope.data['forms']['object_id'] !== 'undefined'
                        && typeof scope.data['forms']['object_type'] !== 'undefined'
                        && ! (scope.data['forms']['object_id'] === item['object_id']
                        && scope.data['forms']['object_type'] === item['object_type']);
                };

                /*****************************************
                 * Data
                 *****************************************/
                function prepareData() {
                    if(typeof scope.data['forms'] !== 'object') {
                        scope.data['forms'] = {};
                    }

                    prepareFilters();
                }

                function prepareFilters() {
                    var filters = scope.info.filters;
                    var data = scope.data['forms'];

                    if(typeof filters === 'undefined') {
                        return;
                    }

                    if(typeof data['filters'] !== 'object' || data['filters'] === null) {
                        data['filters'] = {};
                    }

                    for (var id in filters) {
                        if (!filters.hasOwnProperty(id)) continue;

                        var form = filters[id]['form'];

                        data['filters'][id] = prepareDataForForm(form, data['filters'][id], scope.data);
                    }
                }

                function cleanData() {
                    var cleanableData = scope.data['forms'];
                    var checkableData = angular.copy(scope.data['forms']);

                    cleanFilters(checkableData, cleanableData);
                }

                function cleanFilters(checkableData, cleanableData) {
                    var filters = scope.info.filters;

                    if(typeof filters === 'undefined') {
                        return;
                    }

                    for (var id in filters) {
                        if (!filters.hasOwnProperty(id)) continue;

                        cleanDataForForm(filters[id]['form'], checkableData['filters'][filters[id]['id']], cleanableData['filters'][filters[id]['id']]);
                    }
                }

                /*****************************************
                 * Filters
                 *****************************************/
                function resetFilterForms() {
                    var filters = scope.info['filters'];

                    for(var id in filters) {
                        if (!filters.hasOwnProperty(id)) continue;

                        scope['formFilters'][id].$setUntouched();
                    }
                }

                function validateFilterForms(item) {
                    var valid = true;

                    var allowedFilterValues = item['allowed_filter_values'];

                    for(var id in allowedFilterValues) {
                        if (!allowedFilterValues.hasOwnProperty(id)) continue;

                        scope['formFilters'][id].$setSubmitted();

                        if(scope['formFilters'][id].$pending || !scope['formFilters'][id].$valid) {
                            valid = false;
                        }

                        var allowedValuesPerFilter = item['allowed_filter_values'][id];
                        var currentIdentifier = scope.info['filters'][id]['field_identifier'];
                        var currentValue = scope.data['forms']['filters'][id][currentIdentifier];

                        if(! isValidFilterValue(currentValue, allowedValuesPerFilter)) {
                            valid = false;
                        }
                    }

                    cleanData();
                    return valid;
                }

                function isValidFilterValue(value, allowedValues) {
                    for(var i = 0; i < allowedValues.length; i++) {
                        if(allowedValues[i] === value) {
                            return true;
                        }
                    }

                    return false;
                }

                function showItemBasedOnFilters(item) {
                    var allowedFilterValues = item['allowed_filter_values'];

                    for(var id in allowedFilterValues) {
                        if (!allowedFilterValues.hasOwnProperty(id)) continue;

                        var allowedValuesPerFilter = item['allowed_filter_values'][id];
                        var currentIdentifier = scope.info['filters'][id]['field_identifier'];
                        var currentValue = scope.data['forms']['filters'][id][currentIdentifier];

                        if(currentValue === null || currentValue === '') {
                            continue;
                        }

                        if(! isValidFilterValue(currentValue, allowedValuesPerFilter)) {
                            return false;
                        }
                    }

                    return true;
                }

                function showItemBasedOnRgoAndNpo(item) {
                    var customerGroups = item['item']['customer_groups'];

                    // No Customer Groups specified
                    if(customerGroups.length === 0) {
                        return true;
                    }

                    // Check validity of Customer Groups
                    var valid = false;
                    var customerInformation = {};

                    if(typeof scope.data['customer_information'] === 'object') {
                        customerInformation = scope.data['customer_information'];
                    }

                    customerGroups.forEach(function(customerGroup) {
                        var code = customerGroup['code'];

                        if(code === 'NPO') {
                            if(typeof customerInformation['npo'] !== 'undefined' && customerInformation['npo'] === true) {
                                valid = true;
                                return;
                            }
                        }

                        if(code === 'RGO') {
                            if(typeof customerInformation['rgo'] !== 'undefined' && customerInformation['rgo'] === true) {
                                valid = true;
                                return;
                            }
                        }

                        if(code === 'PRI') {
                            if(typeof customerInformation['entity_type'] !== 'undefined' && customerInformation['entity_type'] === 'PRI') {
                                valid = true;
                                return;
                            }
                        }

                        if(code === 'GES') {
                            if(typeof customerInformation['entity_type'] !== 'undefined' && (customerInformation['entity_type'] === 'EZF' || customerInformation['entity_type'] === 'GES' || customerInformation['entity_type'] === 'OEF')) {
                                valid = true;
                                return;
                            }
                        }
                    });

                    return valid;
                }

                scope.showItem = function(item) {
                    if(scope.isTemplateService()) {
                        return true;
                    }

                    return showItemBasedOnFilters(item) && showItemBasedOnRgoAndNpo(item);
                };

                scope.showSection = function(items) {
                    for (var i = 0; i < items.length; i++) {
                        if (scope.showItem(items[i])) {
                            return true;
                        }
                    }
                    return false;
                };


                /*****************************************
                 * Services
                 *****************************************/
                function prepareServices() {
                    angular.forEach(scope.info.definitions['bundles'], function(bundle) {
                        scope.services.push({
                            'name': Translator.translateVariable('name', bundle.article),
                            'description': Translator.translateVariable('description', bundle.sales_channel),
                            'items': [
                                prepareItemBundle(bundle)
                            ]
                        });
                    });

                    angular.forEach(scope.info.definitions['virtual_bundles'], function (virtualBundle) {
                        scope.services.push({
                            'name': Translator.translateVariable('name', virtualBundle),
                            'description': Translator.translateVariable('description', virtualBundle.sales_channel),
                            'items': [
                                prepareItemVirtualBundle(virtualBundle)
                            ]
                        });
                    });

                    angular.forEach(scope.info.definitions['categories'], function (category) {

                        var categoryDetails = {
                            'name': Translator.translateVariable('name', category),
                            'description': Translator.translateVariable('description', category.sales_channel),
                            'items': []
                        };

                        angular.forEach(category['bundles'], function (bundle) {
                            categoryDetails['items'].push(prepareItemBundle(bundle))
                        });

                        angular.forEach(category['virtual_bundles'], function (virtualBundle) {
                            categoryDetails['items'].push(prepareItemVirtualBundle(virtualBundle))
                        });

                        scope.services.push(categoryDetails);
                    });
                }

                function prepareItem(item, name, type) {
                    var details = {
                        'item': item,
                        'name': name,
                        'object_id': item.id,
                        'object_type': type,
                        'allowed_filter_values': {}
                    };

                    angular.forEach(item.filters, function(filter) {
                        var id = filter['filter_id'];
                        var allowedFilterItems = [];

                        angular.forEach(filter['allowed_filter_items'], function(allowedFilterItem) {
                            allowedFilterItems.push(allowedFilterItem['value']);
                        });

                        details['allowed_filter_values'][id] = allowedFilterItems;
                    });

                    return details;
                }

                function prepareItemBundle(bundle) {
                    return prepareItem(bundle, Translator.translateVariable('name', bundle['article']), 'bundle');
                }

                function prepareItemVirtualBundle(virtualBundle) {
                    return prepareItem(virtualBundle, Translator.translateVariable('name', virtualBundle), 'virtual-bundle');
                }

                /*****************************************
                 * Actions
                 *****************************************/
                scope.actions.validate = function (params) {
                    var item = params.item;

                    // Filter Validation
                    if (scope.parameters['type'] !== 'template') {
                        resetFilterForms();

                        if(! validateFilterForms(item)) {
                            return false;
                        }
                    }

                    // Setting up data
                    if (scope.parameters['type'] !== 'template') {
                        if(typeof scope.data['forms'] !== 'object') {
                            scope.data['forms'] = {};
                        }

                        scope.data['forms']['object_id'] = item.object_id;
                        scope.data['forms']['object_type'] = item.object_type;
                    } else {
                        if(typeof scope.data['template'] !== 'object') {
                            scope.data['template'] = {};
                        }
                        scope.data['template']['object_id'] = item.object_id;
                        scope.data['template']['object_type'] = item.object_type;
                    }

                    // Modify title and sales channel
                    if (scope.parameters['type'] !== 'template') {
                        scope.setTitle(item.name);
                        scope.setSalesChannel(item.item.sales_channel);
                    }

                    return true;
                };

                /*****************************************
                 * Init
                 *****************************************/
                scope.services = [];
                scope.formFilters = {};

                function init() {
                    if(scope.isContractService()) {
                        prepareData();
                    }

                    prepareServices();
                }

                init();
            }
        };
    })

    .service('ServiceSummaryService', function(Translator, grabDefinition, summarizeDataForForm) {

        return {
            getSummary: function(info, data, parameters) {
                var definition = grabDefinition(info, data);
                var summary = [];
                var sectionData = [];

                var filters = definition['filters'];

                for(var i = 0; i < filters.length; i++) {
                    var filterId = filters[i]['filter_id'];
                    var filterForm = info['filters'][filterId]['form'];
                    var filterData = data['forms']['filters'][filterId];
                    var filterFormPath = ['forms', 'filters', filterId];

                    summarizeDataForForm(filterForm, filterData, sectionData, filterFormPath, '')
                }

                summary.push({
                    'section': Translator.translate('forms.service.title'),
                    'data': sectionData
                });

                return summary;
            }
        };
    });
