'use strict';

angular.module('forms.modules.customer',
    [
        'user',
        'forms.helper',
        'forms.components.customer-import',
        'forms.env.country',
        'rol.api',
        'rol.bootstrap-modal',
        'rol.form.validation',
        'rol.translation'
    ]
)

    .factory('CustomerSettingsService', function() {

        var settings = {
            hiddenFields: []
        };

        function hideFields(fields) {
            angular.merge(
                settings.hiddenFields,
                fields
            )
        }

        function isFieldVisible(name) {
            return settings.hiddenFields && settings.hiddenFields.indexOf(name) < 0;
        }

        return {
            hideFields: hideFields,
            isFieldVisible: isFieldVisible
        };
    })

    .directive('customer', function(CustomerSettingsService, ApiService, language, envCountry, $timeout) {

        return {
            restrict: 'E',
            scope: {
                'actions': '=',
                'parameters': '=',
                'info': '=',
                'data': '='
            },
            templateUrl: 'modules/customer/customer.tpl.html',
            link: function(scope) {

                /*****************************************
                 * Defaults
                 *****************************************/
                var defaults = {
                    entity_type: 'PRI',
                    rgo: false,
                    npo: false,
                    language: 'D',
                    province: 'BZ',
                    country: 'IT',
                    vat_code_country: 'IT'
                };

                function setMissingDefaults(data) {
                    for(var key in defaults) {
                        if (!defaults.hasOwnProperty(key)) continue;

                        if(typeof data[key] === 'undefined') {
                            data[key] = defaults[key];
                        }
                    }
                }

                /*****************************************
                 * Settings
                 *****************************************/
                if (scope.parameters.hiddenFields) {
                    CustomerSettingsService.hideFields(scope.parameters.hiddenFields);
                }
                scope.customerSettingsService = CustomerSettingsService;

                /*****************************************
                 * Billing Information
                 *****************************************/
                scope.showCustomerBillingInformation = function () {
                    return scope.customerBillingInformation;
                };

                scope.setPecCodeStringLength = function(entityType) {
                    // GES and EZF
                    var pcl = 7;

                    switch (entityType) {
                        case 'PRI':
                            pcl = 0;
                            break;
                        case 'OEF':
                            pcl = 6;
                            break;
                        default:
                            break;

                    }
                    return pcl;
                };

                scope.toggleCustomerBillingInformation = function () {
                    scope.customerBillingInformation = !scope.customerBillingInformation;

                    if (scope.customerBillingInformation) {
                        prepareCustomerBillingInformation();
                    } else {
                        resetCustomerBillingInformation();
                    }
                };

                function prepareCustomerBillingInformation() {
                    scope.data.customer_billing_information = angular.copy(defaults);

                    if (scope.formCustomerInformation.$submitted) {
                        scope.formCustomerBillingInformation.$setSubmitted();
                    }
                }

                function resetCustomerBillingInformation() {
                    scope.data.customer_billing_information = null;
                    scope.formCustomerBillingInformation.$setUntouched();
                }

                /*****************************************
                 * Customer import
                 *****************************************/
                var importedCustomerInformation = null;

                scope.formsCustomersService = scope.parameters.formsCustomersService;
                scope.rolCustomersService = scope.parameters.rolCustomersService;

                scope.applyCustomer = function(customer) {
                    scope.data.customer_information = customer;
                    importedCustomerInformation = angular.copy(customer);

                    // Remove customer billing information
                    scope.customerBillingInformation = false;
                    resetCustomerBillingInformation();
                };

                var importedCustomerInformationBilling = null;

                scope.applyCustomerBilling = function(customer) {
                    scope.data.customer_billing_information = customer;
                    importedCustomerInformationBilling = angular.copy(customer);
                };

                scope.onFormChange = function() {
                    if (
                        scope.data.customer_information && scope.data.customer_information.customer_id
                        && !angular.equals(importedCustomerInformation, scope.data.customer_information)
                    ) {
                        importedCustomerInformation = null;
                        delete scope.data.customer_information.customer_id;
                    }

                    if (
                        scope.data.customer_billing_information && scope.data.customer_billing_information.customer_id
                        && !angular.equals(importedCustomerInformationBilling, scope.data.customer_billing_information)
                    ) {
                        importedCustomerInformationBilling = null;
                        delete scope.data.customer_billing_information.customer_id;
                    }
                };

                /*****************************************
                 * Validation
                 *****************************************/
                scope.hasValidationErrors = function() {
                    var customerValidationErrors = scope.formCustomerInformation.$submitted && scope.formCustomerInformation.$invalid;
                    var customerBillingValidationErrors = scope.showCustomerBillingInformation() ? scope.formCustomerBillingInformation.$submitted && scope.formCustomerBillingInformation.$invalid : false;

                    return customerValidationErrors || customerBillingValidationErrors;
                };
                
                scope.getValidationClass = function(form, field) {
                    if(scope[form].$submitted && scope[form][field].$invalid) {
                        return 'forms__label--error';
                    }

                    return '';
                };

                /*****************************************
                 * Postcode & Province
                 *****************************************/
                scope.activateItalianAddressValidation = function(data) {
                    if(typeof data === 'undefined' || data === null) {
                        return true;
                    }

                    return data['country'] == 'IT';
                };

                /*****************************************
                 * Fiscal Code & Vat Code
                 *****************************************/
                scope.activateFiscalCodeValidationForFiscalCode = function(data) {
                    if(typeof data === 'undefined' || data === null) {
                        return false;
                    }

                    if(data['country'] != 'IT') {
                        return false;
                    }

                    if(data['entity_type'] == 'EZF') {
                        return true;
                    }

                    if(data['entity_type'] == 'PRI' && !data['npo']) {
                        return true;
                    }

                    return false;
                };

                scope.activateVatCodeValidationForFiscalCode = function(data) {
                    if(typeof data === 'undefined' || data === null) {
                        return false;
                    }

                    if(data['country'] != 'IT') {
                        return false;
                    }

                    return ['PRI', 'EZF'].indexOf(data['entity_type']) <= -1;
                };

                scope.activateAnyCodeValidationForFiscalCode = function(data) {
                    if(typeof data === 'undefined' || data === null) {
                        return false;
                    }

                    if(data['country'] != 'IT') {
                        return false;
                    }

                    return data['entity_type'] == 'PRI' && data['npo'];
                };

                scope.activateVatCodeValidationForVatCode = function(data) {
                    if(typeof data === 'undefined' || data === null) {
                        return false;
                    }

                    return data['vat_code_country'] == 'IT';
                };

                scope.activateVatCodeValidationWithNotLeadingEightOrNine = function(data) {

		    return false;

                    if(typeof data === 'undefined' || data === null) {
                        return false;
                    }

                    if (data['vat_code_country'] != 'IT') {
                        return false;
                    }

                    if (typeof data['fiscal_code'] === 'undefined') {
                        return false;
                    }

                    return !(/^(?![89])[0-9]+$/.test(data['fiscal_code']));
                };

                scope.isFiscalCodeRequired = function(data) {
                    if(typeof data === 'undefined' || data === null) {
                        return false;
                    }

                    if (data['country'] == 'IT') {
                        return true;
                    }

                    return ['PRI', 'OEF'].indexOf(data['entity_type']) > -1;
                };
                
                scope.isVatCodeRequired = function(data) {
                    if(typeof data === 'undefined' || data === null) {
                        return false;
                    }

                    return ['EZF', 'GES'].indexOf(data['entity_type']) > -1;
                };

                scope.isVatCodeDisabled = function(data) {
                    if(typeof data === 'undefined' || data === null) {
                        return false;
                    }

                    return data['entity_type'] == 'PRI';
                };

                scope.isPecCodeDisabled = function(customerInformation) {

                    var pecCodeIsDisabled = true;

                    if (!(typeof customerInformation.pec === 'undefined')) {

                        var re = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
                        var regexResult = re.test(String(customerInformation.pec).toLowerCase());

                        if (regexResult) {
                            pecCodeIsDisabled = false;
                        }
                    }
                    return pecCodeIsDisabled;
                }

                scope.evaluatePecFieldsVisibility = function(entityType, country) {

                    if (entityType === 'PRI' || country !== 'IT') {
                        return false;
                    }
                    return true;
                };

                scope.handleEntitySelection = function(entityType, type) {

                    if (entityType == 'EZF' || entityType == 'OEF') {
                        if (type == 'billing') {
                            scope.data.customer_billing_information.country = 'IT';
                            scope.data.customer_billing_information.vat_code_country = 'IT';
                        } else {
                            scope.data.customer_information.country = 'IT';
                            scope.data.customer_information.vat_code_country = 'IT';
                        }
                    }
                };


                /*****************************************
                 * Countries
                 *****************************************/
                var countries = [];

                scope.getCountries = function() {
                    return countries;
                };

                /*****************************************
                 * Actions
                 *****************************************/
                scope.actions.validate = function () {
                    // Submission
                    if (typeof scope.parameters.skip === 'undefined' || scope.parameters.skip === false) {
                        scope.formCustomerInformation.$setSubmitted();

                        if (scope.showCustomerBillingInformation()) {
                            scope.formCustomerBillingInformation.$setSubmitted();
                        }
                    }

                    // Validate input
                    if (scope.formCustomerInformation.$valid === false) {
                        return false;
                    }

                    if (scope.showCustomerBillingInformation() && scope.formCustomerBillingInformation.$valid === false) {
                        return false;
                    }

                    return true;
                };

                scope.actions.clean = function () {
                    if(scope.isVatCodeDisabled(scope.data.customer_information)) {
                        delete scope.data.customer_information['vat_code'];
                        delete scope.data.customer_information['vat_code_country'];
                    }

                    if(scope.isVatCodeDisabled(scope.data.customer_billing_information)) {
                        delete scope.data.customer_billing_information['vat_code'];
                        delete scope.data.customer_billing_information['vat_code_country'];
                    }

                    return true;
                };

                /*****************************************
                 * Init
                 *****************************************/
                function init() {
                    ApiService.get(envCountry['url'], function(data) {
                        data.forEach(function(country) {
                           countries.push({
                               'key': country['alpha2'],
                               'value': country[language]
                           });
                        });
                    }, function() {});

                    if (
                        typeof scope.data.customer_information === 'undefined'
                        || scope.data.customer_information === null
                        || angular.equals({}, scope.data.customer_information)
                        || angular.equals([], scope.data.customer_information)
                    ) {
                        scope.data.customer_information = angular.copy(defaults);
                    } else {
                        setMissingDefaults(scope.data['customer_information']);
                    }

                    if (
                        typeof scope.data.customer_billing_information === 'undefined'
                        || scope.data.customer_billing_information === null
                        || angular.equals({}, scope.data.customer_billing_information)
                        || angular.equals([], scope.data.customer_billing_information)
                    ) {
                        scope.data.customer_billing_information = null;
                    } else {
                        setMissingDefaults(scope.data['customer_billing_information']);
                    }

                    scope.customerBillingInformation = (scope.data.customer_billing_information !== null);
                }

                init();
            }
        }})

    .service('CustomerSummaryService', function(CustomerSettingsService, extractNestedValue, Translator) {

        function getCustomerInformationData(customerInformation, identifierPrefix) {

            var customerTypes = {
                'PRI': Translator.translate('forms.customer_information.entity_type-private'),
                'EZF': Translator.translate('forms.customer_information.entity_type-small_company'),
                'GES': Translator.translate('forms.customer_information.entity_type-big_company'),
                'OEF': Translator.translate('forms.customer_information.entity_type-public_institution')
            };

            var entityType = extractNestedValue(customerInformation, 'entity_type');
            var customerType = [
                typeof customerTypes[entityType] !== 'undefined' ? customerTypes[entityType] : null
            ];

            if (extractNestedValue(customerInformation, 'rgo')) {
                customerType.push(Translator.translate('forms.customer_information.entity_type-rgo'));
            }

            if (extractNestedValue(customerInformation, 'npo')) {
                customerType.push(Translator.translate('forms.customer_information.entity_type-npo'));
            }

            return [
                CustomerSettingsService.isFieldVisible('entity_type')
                    ? {
                        'identifier': identifierPrefix + 'entity_type',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.entity_type'),
                        'value': customerType.join(', ')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('language')
                    ? {
                        'identifier': identifierPrefix + 'language',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.language')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('name')
                    ? {
                        'identifier': identifierPrefix + 'name',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.name')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('address')
                    ? {
                        'identifier': identifierPrefix + 'address',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.address')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('postcode')
                    ? {
                        'identifier': identifierPrefix + 'postcode',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.postcode')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('city')
                    ? {
                        'identifier': identifierPrefix + 'city',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.city')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('province')
                    ? {
                        'identifier': identifierPrefix + 'province',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.province')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('country')
                    ? {
                        'identifier': identifierPrefix + 'country',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.country')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('phone')
                    ? {
                        'identifier': identifierPrefix + 'phone',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.phone')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('cellphone')
                    ? {
                    'identifier': identifierPrefix + 'cellphone',
                    'type': 'key-value',
                    'label': Translator.translate('forms.customer_information.cellphone')
                } : null,
                CustomerSettingsService.isFieldVisible('fax')
                    ? {
                        'identifier': identifierPrefix + 'fax',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.fax')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('email')
                    ? {
                        'identifier': identifierPrefix + 'email',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.email')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('pec_address')
                    ? {
                        'identifier': identifierPrefix + 'pec_address',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.pec_address')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('pec_code')
                    ? {
                        'identifier': identifierPrefix + 'pec_code',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.pec_code')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('fiscal_code')
                    ? {
                        'identifier': identifierPrefix + 'fiscal_code',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.fiscal_code')
                    }
                    : null,
                CustomerSettingsService.isFieldVisible('vat_code')
                    ? {
                        'identifier': identifierPrefix + 'vat_code',
                        'type': 'key-value',
                        'label': Translator.translate('forms.customer_information.vat_code')
                    }
                    : null
            ];
        }



        return {
            getSummary: function(info, data, parameters) {

                var summary = [{
                    'section': Translator.translate('forms.customer_information.title'),
                    'data': getCustomerInformationData(extractNestedValue(data, 'customer_information'), 'customer_information.')
                }];

                if (CustomerSettingsService.isFieldVisible('billing_information')) {
                    if (data.customer_billing_information) {
                        summary.push({
                            'section': Translator.translate('forms.customer_information.billing-information-title'),
                            'data': getCustomerInformationData(extractNestedValue(data, 'customer_billing_information'), 'customer_billing_information.')
                        });
                    } else {
                        summary[0].data.push({
                            'value': Translator.translate('forms.customer_information.billing-information-selection')
                        });
                    }
                }

                return summary;
            }
        };
    });
