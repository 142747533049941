'use strict';

angular.module('rol.form',
    [
        'rol.api',
        'rol.bootstrap-modal',
        'rol.form.dependency',
        'rol.form.fields.email-limited-domain',
        'rol.form.fields.geo-location',
        'rol.form.validation',
        'rol.translation'
    ]
    )

    .directive('rolForm', function(Translator, ChoiceSourceService, LoadableFieldsManager, DependenciesValidator) {
        return {
            restrict: 'E',
            scope: {
                'definition': '=',
                'data': '=',
                'form': '=',
                'identifierPrefix': '=',
                'stylesPrefix': '=',
                'labelPrefix': '@?'
            },
            templateUrl: 'form.tpl.html',

            link: function(scope) {

                /*****************************************
                 * Dependencies
                 *****************************************/
                scope.hasValidDependencies = function(field, data) {
                    return DependenciesValidator.validate(field, data);
                };

                /*****************************************
                 * Help
                 *****************************************/
                scope.hasHelp = function(field) {
                    var text = scope.getHelp(field);

                    if (typeof text === 'undefined') {
                        return false;
                    }

                    return (text !== null && text !== '');
                };

                scope.getHelp = function(field) {
                    return Translator.translateVariable('help', field);
                };

                /*****************************************
                 * Choices
                 *****************************************/
                scope.isLimitedEmail = function(field) {
                    var identifier = field['identifier'];
                    var additionalInformation = field['additional_information'];

                    if(typeof additionalInformation !== 'object' || additionalInformation === null) {
                        return false;
                    }

                    if(typeof additionalInformation['domains'] !== 'object' || additionalInformation['domains'] === null) {
                        return false;
                    }

                    return true;
                };

                scope.getDomains = function(field) {
                    var additionalInformation = field['additional_information'];

                    return additionalInformation['domains'];
                };

                /*****************************************
                 * Choices
                 *****************************************/
                var choices = {};

                scope.getChoices = function(field) {
                    var identifier = field['identifier'];
                    var additionalInformation = field['additional_information'];

                    if(identifier in choices) {
                        return choices[identifier];
                    }

                    choices[identifier] = [];

                    if(typeof additionalInformation['source'] !== 'undefined') {
                        var url = additionalInformation['source'];

                        LoadableFieldsManager.setLoading(identifier);

                        ChoiceSourceService.get(url, function(data) {
                            for(var i = 0; i < data.length; i++) {
                                var id = data[i]['key'];
                                var value = Translator.translateVariable('value', data[i]);

                                if (typeof additionalInformation['validator_values'] !== 'undefined') {
                                    var filteredValues = additionalInformation['validator_values'].filter(function(value) {
                                        return value == id;
                                    });

                                    if (filteredValues.length <= 0) {
                                        continue;
                                    }
                                }

                                choices[identifier].push({
                                    'key': id,
                                    'value': value
                                });
                            }

                            LoadableFieldsManager.unsetLoading(identifier);
                        }, function() {
                            LoadableFieldsManager.unsetLoading(identifier);
                        });
                    } else if(typeof additionalInformation['items'] !== 'undefined') {
                        for (var id in additionalInformation['items']) {
                            if (!additionalInformation['items'].hasOwnProperty(id)) continue;

                            choices[identifier].push({
                                'key': id,
                                'value': Translator.translateVariable('name', additionalInformation['items'][id]),
                                'position': additionalInformation['items'][id]['position']
                            });
                        }
                    }

                    return choices[identifier];
                };

                scope.getSort = function(field) {
                    var additionalInformation = field['additional_information'];

                    if(typeof additionalInformation['sort'] === 'undefined') {
                        return 'none';
                    }

                    if(additionalInformation['sort'] === 'position') {
                        return 'position';
                    }

                    if(additionalInformation['sort'] === 'value') {
                        return 'value';
                    }

                    return 'none';
                };

                scope.choiceOrder = function(field) {
                    var sort = scope.getSort(field);

                    return function(choice) {
                        if(sort === 'position') {
                            return choice['position'];
                        }

                        if(sort === 'value') {
                            return choice['value'];
                        }

                        return;
                    };
                };

                scope.isLoading = function(identifier) {
                    return LoadableFieldsManager.isLoading(identifier);
                };


                /*****************************************
                 * Validation
                 *****************************************/
                scope.hasValidationErrors = function(fieldIdentifier) {
                    return scope['form'].$submitted && typeof scope['form'][fieldIdentifier] === 'object' && scope['form'][fieldIdentifier].$invalid;
                };

                scope.getValidationClass = function(fieldIdentifier) {
                    if(! scope.hasValidationErrors(fieldIdentifier)) {
                        return '';
                    }

                    return 'forms__label--error';
                };

                scope.getValidationMessage = function(field, fieldIdentifier) {
                    if(! scope.hasValidationErrors(fieldIdentifier)) {
                        return '';
                    }

                    var errors = scope['form'][fieldIdentifier]['$error'];

                    if(typeof errors['customValidation'] === 'boolean' && errors['customValidation'] === true) {
                        return scope.validationMessage(field);
                    }

                    return '';
                };

                scope.validate = function(field) {
                    return typeof field['additional_information'] === 'object'
                        && field['additional_information'] !== null
                        && (typeof field['additional_information']['validator_url'] !== 'undefined' || typeof field['additional_information']['validator_values'] !== 'undefined');
                };

                scope.validationUrl = function(field) {
                    if(scope.validate(field) && typeof field['additional_information']['validator_url'] !== 'undefined') {
                        return field['additional_information']['validator_url'];
                    }

                    return null;
                };

                scope.validationValues = function(field) {
                    if(scope.validate(field) && typeof field['additional_information']['validator_values'] !== 'undefined') {
                        return field['additional_information']['validator_values'];
                    }

                    return null;
                };

                scope.validationMessage = function(field) {
                    if(
                        typeof field['additional_information'] === 'object' &&
                        field['additional_information'] !== null &&
                        typeof field['additional_information']['validator_message'] === 'object' &&
                        field['additional_information']['validator_message'] !== null
                    ) {
                        return Translator.translateVariable('message', field['additional_information']['validator_message']);
                    }

                    return '';
                };
            }
        };
    })

    .service('ChoiceSourceService', function (ApiService, $http) {

        var states = {};
        var caches = {};

        var intermediates = {};

        function get(url, successCallback, failureCallback) {
            // Cached values
            if(url in caches) {
                successCallback(caches[url]);
                return;
            }

            // Request resource
            function cachedSuccessCallback(data) {
                caches[url] = data;
                states[url] = 'FETCHED';

                intermediates[url].forEach(function(item) {
                   item['successCallback'](data);
                });

                successCallback(data);
            }

            function cachedFailureCallback(data) {
                caches[url] = [];
                states[url] = 'FETCHED';

                intermediates[url].forEach(function(item) {
                    item['failureCallback'](data);
                });

                failureCallback(data);
            }

            if(states[url] === 'FETCHING') {
                intermediates[url].push({
                    'successCallback': successCallback,
                    'failureCallback': failureCallback
                });
            } else {
                states[url] = 'FETCHING';
                intermediates[url] = [];

                ApiService.get(url, cachedSuccessCallback, cachedFailureCallback);
            }
        }

        return {
            get: get
        };
    })

    .factory('prepareDataForForm', function(ChoiceSourceService, LoadableFieldsManager) {
        function getDefaultFieldValue(identifier, additionalInformation, formData, allData) {
            if(typeof additionalInformation !== 'object' || additionalInformation === null) {
                return undefined;
            }

            if(typeof additionalInformation['default'] !== 'object' || additionalInformation['default'] === null) {
                return undefined;
            }

            switch(additionalInformation['default']['type']) {
                case 'static':
                    return additionalInformation['default']['value'];

                case 'data':
                    var path = additionalInformation['default']['path'];
                    var value = allData;

                    for(var i = 0; i < path.length; i++) {
                        var identifier = path[i];

                        if(typeof value[identifier] === 'undefined') {
                            return undefined;
                        }

                        value = value[identifier];
                    }

                    return value;

                default:
                    return undefined;
            }
        }

        function prepareCheckbox(identifier, additionalInformation, formData, allData) {
            if(formData[identifier] !== true && formData[identifier] !== false) {
                formData[identifier] = false;
            }
        }

        function prepareChoice(identifier, additionalInformation, fromData, allData) {

            if(typeof additionalInformation['source'] !== 'undefined') {

                var url = additionalInformation['source'];

                LoadableFieldsManager.setLoading(identifier);

                ChoiceSourceService.get(url, function(result) {
                    var items = {};

                    result.forEach(function(item) {
                        var key = item['key'];

                        items[key] = {
                            'key': key
                        };
                    });

                    if(! (fromData[identifier] in items)) {
                        fromData[identifier] = getChoiceDefaultValue(identifier, additionalInformation, fromData, allData);
                    }

                    LoadableFieldsManager.unsetLoading(identifier);

                }, function() {
                    fromData[identifier] = getChoiceDefaultValue(identifier, additionalInformation, fromData, allData);
                    LoadableFieldsManager.unsetLoading(identifier);
                });
            } else if(typeof additionalInformation['items'] !== 'undefined') {
                if(! (fromData[identifier] in additionalInformation['items'])) {
                    fromData[identifier] = getChoiceDefaultValue(identifier, additionalInformation, fromData, allData);
                }
            }
        }

        function getChoiceDefaultValue(identifier, additionalInformation, formData, allData) {
            var defaultFieldValue = getDefaultFieldValue(identifier, additionalInformation, formData, allData);

            return defaultFieldValue === undefined
                ? Object.keys(additionalInformation['items'])[0]
                : defaultFieldValue;
        }

        function prepareEmail(identifier, additionalInformation, formData, allData) {
            var limited = (typeof additionalInformation === 'object' && additionalInformation !== null && Object.prototype.toString.call(additionalInformation['domains']) === '[object Array]' && additionalInformation['domains'].length > 0);

            if(limited) {
                var account = '';
                var domain = '';
                var domains = additionalInformation['domains'];

                if(typeof formData[identifier] === 'string') {
                    var parts = formData[identifier].split('@');

                    if(parts.length >= 1) {
                        account = parts[0];
                    }

                    if(parts.length >= 2) {
                        domain = '@' + parts[1];

                        if(domains.indexOf(domain) < 0) {
                            domain = domains[0];
                        }
                    }
                }

                formData[identifier] = account + domain;
            }
        }

        return function(form, formData, allData) {

            if(typeof formData !== 'object' || formData === null) {
                formData = {};
            }

            for (var j = 0; j < form['form_fields'].length; j++) {
                var fieldIdentifier = form['form_fields'][j]['identifier'];
                var fieldType = form['form_fields'][j]['type'];
                var fieldAdditionalInformation = form['form_fields'][j]['additional_information'];

                if(typeof formData[fieldIdentifier] === 'undefined') {
                    var fieldDefault = getDefaultFieldValue(fieldIdentifier, fieldAdditionalInformation, formData, allData);

                    if(typeof fieldDefault === 'undefined') {
                        formData[fieldIdentifier] = '';
                    } else {
                        formData[fieldIdentifier] = fieldDefault;
                    }
                }

                switch(fieldType) {
                    case 'checkbox':
                        prepareCheckbox(fieldIdentifier, fieldAdditionalInformation, formData, allData);
                        break;
                    case 'select':
                    case 'radio':
                        prepareChoice(fieldIdentifier, fieldAdditionalInformation, formData, allData);
                        break;
                    case 'email':
                        prepareEmail(fieldIdentifier, fieldAdditionalInformation, formData, allData);
                        break;
                    default:
                        break;
                }
            }

            return formData;
        }
    })

    .factory('cleanDataForForm', function(DependenciesValidator, Translator) {
        function cleanEmail(data, identifier, additionalInformation) {
            if (data[identifier] === undefined) {
                data[identifier] = '';
                return;
            }

            var parts = data[identifier].split('@');

            if(parts.length !== 2) {
                data[identifier] = '';
                return;
            }

            if(parts[0] === '') {
                data[identifier] = '';
            }
        }

        function sortByPosition(formfields) {
            var sortedFormFields = [];

            for (var i = 0; i < formfields.length; i++) {
                var position = formfields[i]['position'];
                sortedFormFields[position] = formfields[i];
            }

            return sortedFormFields;
        }

        return function(form, checkableData, cleanableData) {
            form['form_fields'] = sortByPosition(form['form_fields']);

            for (var j = 0; j < form['form_fields'].length; j++) {
                var field = form['form_fields'][j];
                var fieldIdentifier = field['identifier'];
                var fieldType = field['type'];
                var fieldAdditionalInformation = field['additional_information'];

                if ( ! DependenciesValidator.validate(field, checkableData)) {
                    delete cleanableData[fieldIdentifier];
                    continue;
                }

                switch(fieldType) {
                    case 'email':
                        cleanEmail(cleanableData, fieldIdentifier, fieldAdditionalInformation);
                        break;
                    case'note':
                        cleanableData[fieldIdentifier] = Translator.translateVariable('help', field);
                        break;
                    default:
                        break;
                }
            }

            return cleanableData;
        }
    })

    .factory('summarizeDataForForm', function(Translator, ChoiceSourceService, DependenciesValidator) {

        return function(form, data, sectionData, identifierPath, style) {
            var fields = form['form_fields'];

            for (var k = 0; k < fields.length; k++) {

                var field = fields[k];
                var fieldIdentifier = field['identifier'];
                var fieldPath = identifierPath.concat([fieldIdentifier]);
                var fieldValue = data[fieldIdentifier];

                if (DependenciesValidator.validate(field, data)) {

                    var entry = {
                        'type': 'key-value',
                        'style': style,
                        'identifier': fieldPath.join('.'),
                        'label': Translator.translateVariable('title', field),
                        'value': fieldValue
                    };

                    if (field.type === 'select' || field.type === 'radio') {
                        if(typeof field['additional_information'] === 'object' && field['additional_information'] !== null) {

                            if(typeof field['additional_information']['items'] !== 'undefined' && typeof field['additional_information']['items'][fieldValue] !== 'undefined') {
                                entry['value'] = Translator.translateVariable('name', field.additional_information.items[fieldValue])
                            }

                            if(typeof field['additional_information']['source'] !== 'undefined') {
                                ChoiceSourceService.get(field['additional_information']['source'], function(data) {
                                    for(var i = 0; i < data.length; i++) {
                                        if(data[i]['key'] == fieldValue) {
                                            entry['value'] = Translator.translateVariable('value', data[i]);
                                            break;
                                        }
                                    }
                                }, function() {});
                            }
                        }
                    }

                    if (field.type === 'checkbox') {
                        entry['value'] = fieldValue
                            ? Translator.translate('rol.form.yes')
                            : Translator.translate('rol.form.no');
                    }

                    if (field.type === 'note') {
                        entry['type'] = 'note';
                    }

                    sectionData.push(entry);
                }
            }
        }
    })

    .factory('LoadableFieldsManager', function() {

        var fieldsLoading = {};

        function isLoading(identifier) {
            return fieldsLoading[identifier] !== undefined;
        }

        function setLoading(identifier) {
            fieldsLoading[identifier] = true;
        }

        function unsetLoading(identifier) {
            delete fieldsLoading[identifier];
        }

        return {
            isLoading: isLoading,
            setLoading: setLoading,
            unsetLoading: unsetLoading
        };
    });
