'use strict';

angular.module('forms.modules.forms.bundle-price',
    [
        'rol.translation'
    ]
)

    .directive('bundlePrice', function() {
        return {
            restrict: 'E',
            scope: {
                'priceInformation': '=',
                'priceClass': '@',
                'enabled': '=?',
                'contractPeriodicity': '=?'
            },
            templateUrl: 'modules/forms/bundle-price/bundle-price.tpl.html',

            link: function(scope) {
                scope.enabled = scope.enabled === undefined ? true : scope.enabled;
            }
        };
    });