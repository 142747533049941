'use strict';

angular.module('rol.form.validation',
    [
        'rol.api'
    ]
)

    /***********************************************************
     * Angular Overrides
     ***********************************************************/
    .directive('input', function() {
        return {
            restrict: 'E',
            require: '?ngModel',
            link: function(scope, elem, attr, ngModel) {
                if (ngModel && ngModel.$validators.email) {
                    ngModel.$validators.email = function(value) {
                        return ngModel.$isEmpty(value)
                            || /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(value);
                    };
                }
            }
        };
    })

    /***********************************************************
     * General
     ***********************************************************/
    .directive('customValidation', ['$q', '$http', 'ApiService', function($q, $http, ApiService) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attr, ngModel) {
                var enabled = true;
                var defaultOptions = null;
                var optimizedOptions = {
                    updateOnDefault: true,
                    debounce: {
                        'default': 300
                    }
                };

                // Validation Condition
                if(typeof attr.customValidationCondition !== 'undefined') {
                    enabled = scope.$eval(attr.customValidationCondition);
                    defaultOptions = ngModel.$options;

                    scope.$watch(function() {
                        enabled = scope.$eval(attr.customValidationCondition);

                        return enabled;
                    }, function(enabled) {
                        if(enabled) {
                            ngModel.$options = optimizedOptions;
                        } else {
                            ngModel.$options = defaultOptions;
                        }

                        ngModel.$validate();
                    });
                }

                // Validation
                var customValidationUrl = scope.$eval(attr.customValidationUrl);
                var customValidationValues = scope.$eval(attr.customValidationValues);

                ngModel.$asyncValidators.customValidation = function(modelValue, viewValue) {
                    if (!enabled || ngModel.$isEmpty(modelValue)) {
                        return $q.when();
                    }

                    var deferred = $q.defer();

                    if (typeof customValidationUrl !== 'undefined' && customValidationUrl !== null) {
                        // Url
                        var successCallback = function(data) {
                            if(typeof data['valid'] === 'boolean' && data['valid']) {
                                deferred.resolve();
                            } else {
                                deferred.reject();
                            }
                        };

                        var failureCallback = function() {
                            deferred.reject();
                        };

                        var customValidationData = {
                            'value': modelValue
                        };

                        ApiService.post(customValidationUrl, customValidationData, successCallback, failureCallback);
                    } else if (typeof customValidationValues !== 'undefined' && customValidationValues !== null) {
                        // Values
                        var valid = false;

                        customValidationValues.forEach(function(value) {
                            if (value == modelValue) {
                                valid = true;
                            }
                        });

                        if (valid) {
                            deferred.resolve();
                        } else {
                            deferred.reject();
                        }
                    } else {
                        // Nothing
                        deferred.resolve();
                    }

                    return deferred.promise;
                };
            }
        };
    }])

    .directive('validIfDisabled', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelController) {
                function checkDisabled() {
                    if (scope.$eval(attrs.ngDisabled)) {
                        Object.keys(ngModelController.$validators)
                            .forEach(function (type) {
                                ngModelController.$setValidity(type, true);
                            });
                    } else {
                        ngModelController.$validate();
                    }
                }

                scope.$watch(
                    function() { return attrs.disabled; },
                    function() { checkDisabled(); }
                );
                scope.$watch(
                    function() { return ngModelController.$valid },
                    function() { checkDisabled(); }
                );
            }
        }
    })

    /***********************************************************
     * Person
     ***********************************************************/
    .directive('email', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attr, ngModel) {
                ngModel.$validators.email = function(value) {
                    return ngModel.$isEmpty(value)
                        || /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(value);
                };
            }
        };
    })

    .directive('phoneNumber', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attr, ngModel) {
                var hasFocus = false;

                element.on('blur', function() {
                    hasFocus = false;
                    ngModel.$parsers.phoneNumber(ngModel.$viewValue);
                });

                element.on('focus', function() {
                    hasFocus = true;
                });

                ngModel.$parsers.phoneNumber = function(value) {
                    if (!hasFocus && value) {
                        value = value.replace(/[\s\-\.]/g, '');
                        value = value.replace(/^00/g, '+');
                        if (!/^\+/.test(value)) {
                            value = '+39'+value;
                        }
                        ngModel.$setViewValue(value);
                        ngModel.$commitViewValue();
                        ngModel.$render();
                    }
                    return value;
                };

                ngModel.$validators.phoneNumber = function(modelValue) {
                    if (modelValue == undefined || modelValue == '' ) {
                        return true;
                    }
                    if (hasFocus) {
                        return /^\+?[\d\s\-\.]{8,}$/.test(modelValue);
                    }
                    return /^\+\d{8,}$/.test(modelValue);
                }
            }
        };
    })

    .directive('birthday', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attr, ngModel) {
                var hasFocus = false;

                element.on('blur', function() {
                    hasFocus = false;
                    ngModel.$parsers.birthday(ngModel.$viewValue);
                    ngModel.$validate();

                });

                element.on('focus', function() {
                    hasFocus = true;
                });

                ngModel.$parsers.birthday = function(value) {
                    if (!hasFocus && value) {
                        value = value.replace(/[\s]/g, '');
                        value = value.replace(/[\/]/g, '.');

                        var parts = value.split('.');
                        var date = '';

                        // Day
                        if(parts.length >= 1) {
                            var day = parseInt(parts[0]) || 1;

                            if(day < 10) {
                                date += '0' + day;
                            } else if(day > 31) {
                                date += '01';
                            } else {
                                date += day;
                            }
                        } else {
                            date += '01';
                        }

                        date += '.';

                        // Month
                        if(parts.length >= 2) {
                            var month = parseInt(parts[1]) || 1;

                            if(month < 10) {
                                date += '0' + month;
                            } else if(month > 12) {
                                date += '01';
                            } else {
                                date += month;
                            }
                        } else {
                            date += '01';
                        }

                        date += '.';

                        // Year
                        if(parts.length >= 3) {
                            var year = parseInt(parts[2]) || 0;

                            if(year < 10) {
                                date += '200' + year;
                            } else if(year < 100) {
                                date += '20' + year;
                            } else if(year < 1000) {
                                date += '2' + year;
                            } else if(year > 3000) {
                                date += '2000';
                            } else {
                                date += year;
                            }
                        } else {
                            date += '2000';
                        }

                        ngModel.$setViewValue(date);
                        ngModel.$commitViewValue();
                        ngModel.$render();
                    }
                    return value;
                };

                ngModel.$validators.birthday = function(modelValue) {
                    if (modelValue == undefined || modelValue == '' ) {
                        return true;
                    }
                    if (hasFocus) {
                        return /^[0-3]?[0-9](\.|\/)[0-1]?[0-9](\.|\/)[0-9]{4}$/.test(modelValue);
                    }
                    return /^[0-3]?[0-9]\.[0-1]?[0-9]\.[0-9]{4}$/.test(modelValue);
                }
            }
        };
    })

    .directive('italianZipCode', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {
                var isEnabled = true;

                scope.$watch(function() {
                    isEnabled = scope.$eval(attr.italianZipCode);
                    return isEnabled;
                }, function() {
                    ngModel.$parsers.italianZipCode(ngModel.$viewValue);
                    ngModel.$validate();
                });

                ngModel.$parsers.italianZipCode = function(value) {
                    return value;
                };

                ngModel.$validators.italianZipCode = function(modelValue) {
                    if (!isEnabled
                        || modelValue === undefined
                        || modelValue === null
                        || modelValue === ''
                    ) {
                        return true;
                    }

                    return /^[0-9]{5}$/.test(modelValue);
                }
            }
        };
    })

    .directive('italianProvinceCode', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {
                var isEnabled = true;

                scope.$watch(function() {
                    isEnabled = scope.$eval(attr.italianProvinceCode);
                    return isEnabled;
                }, function() {
                    ngModel.$parsers.italianProvinceCode(ngModel.$viewValue);
                    ngModel.$validate();
                });

                ngModel.$parsers.italianProvinceCode = function(value) {
                    return value;
                };

                ngModel.$validators.italianProvinceCode = function(modelValue) {
                    if (!isEnabled
                        || modelValue === undefined
                        || modelValue === null
                        || modelValue === ''
                    ) {
                        return true;
                    }

                    return /^[A-Za-z]{2,3}$/.test(modelValue);
                }
            }
        };
    })

    /***********************************************************
     * Bank
     ***********************************************************/
    .directive('iban', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {

                ngModel.$validators.iban = function(modelValue) {
                    return isValidIBANNumber(modelValue);
                };

                function isValidIBANNumber(input) {
                    var CODE_LENGTHS = {
                        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
                        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
                        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
                        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
                        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
                        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
                        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
                    };
                    var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''),
                        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/),
                        digits;
                    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
                        return false;
                    }
                    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
                        return letter.charCodeAt(0) - 55;
                    });
                    return mod97(digits) === 1;
                }

                function mod97(string) {
                    var checksum = string.slice(0, 2), fragment;
                    for (var offset = 2; offset < string.length; offset += 7) {
                        fragment = String(checksum) + string.substring(offset, offset + 7);
                        checksum = parseInt(fragment, 10) % 97;
                    }
                    return checksum;
                }
            }
        }
    })

    .directive('swiftCode', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attr, ngModel) {
                ngModel.$validators.swiftCode = function (modelValue) {
                    if (modelValue === undefined || modelValue === null || modelValue === '') {
                        return true;
                    }
                    return /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/.test(modelValue);
                };
            }
        };
    })

    /***********************************************************
     * Tax Code
     ***********************************************************/
    .service('ItalianCodeService', function() {

        function fiscalCodeValidation(code) {
            var fiscalCode = code.toUpperCase();

            if (!/^[A-Z0-9]{16}$/.test(fiscalCode)) {
                return false;
            }

            var set1 = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            var set2 = 'ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ';
            var setEven = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            var setOdd = 'BAKPLCQDREVOSFTGUHMINJWZYX';

            var i;
            var s = 0;

            for (i = 1; i <= 13; i += 2) {
                s += setEven.indexOf(set2.charAt(set1.indexOf(fiscalCode.charAt(i))));
            }
            for (i = 0; i <= 14; i += 2) {
                s += setOdd.indexOf(set2.charAt(set1.indexOf(fiscalCode.charAt(i))));
            }
            return s % 26 == (fiscalCode.charCodeAt(15) - 'A'.charCodeAt(0));
        }

        function vatCodeValidation(code) {
            var vatCode = code.toUpperCase();

            return /^([0-9]{11})$/.test(vatCode);
        }

        function fiscalCodeParser(code) {
            return code;
        }

        function vatCodeParser(code) {
            if(code.length == 13) {
                code = code.replace(/^IT/ig, '');
            }

            return code;
        }

        return {
            fiscalCodeValidation: fiscalCodeValidation,
            vatCodeValidation: vatCodeValidation,

            fiscalCodeParser: fiscalCodeParser,
            vatCodeParser: vatCodeParser
        }

    })

    .directive('italianAnyCode', ['ItalianCodeService', function(ItalianCodeService) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {
                var isEnabled = true;

                scope.$watch(function() {
                    isEnabled = scope.$eval(attr.italianAnyCode);
                    return isEnabled;
                }, function() {
                    ngModel.$parsers.italianAnyCode(ngModel.$viewValue);
                    ngModel.$validate();
                });

                var hasFocus = false;

                element.on('blur', function() {
                    hasFocus = false;
                    ngModel.$parsers.italianAnyCode(ngModel.$viewValue);
                });
                element.on('focus', function() {
                    hasFocus = true;
                });

                ngModel.$parsers.italianAnyCode = function(value) {
                    if (isEnabled && !hasFocus && value) {
                        value = ItalianCodeService.vatCodeParser(value);
                        value = ItalianCodeService.fiscalCodeParser(value);

                        ngModel.$setViewValue(value);
                        ngModel.$commitViewValue();
                        ngModel.$render();
                    }
                    return value;
                };

                ngModel.$validators.italianAnyCode = function(modelValue) {
                    if (!isEnabled
                        || modelValue === undefined
                        || modelValue === null
                        || modelValue === ''
                    ) {
                        return true;
                    }

                    return ItalianCodeService.fiscalCodeValidation(modelValue) || ItalianCodeService.vatCodeValidation(modelValue);
                }
            }
        };
    }])

    .directive('italianFiscalCode', ['ItalianCodeService', function(ItalianCodeService) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {
                var isEnabled = true;

                scope.$watch(function() {
                    isEnabled = scope.$eval(attr.italianFiscalCode);
                    return isEnabled;
                }, function() {
                    ngModel.$validate();
                });

                ngModel.$validators.italianFiscalCode = function(modelValue) {

                    if (!isEnabled
                        || modelValue === undefined
                        || modelValue === null
                        || modelValue === ''
                    ) {
                        return true;
                    }

                    return ItalianCodeService.fiscalCodeValidation(modelValue);
                }
            }
        };
    }])

    .directive('italianVatCode', ['ItalianCodeService', function(ItalianCodeService) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {
                var isEnabled = true;

                scope.$watch(function() {
                    isEnabled = scope.$eval(attr.italianVatCode);
                    return isEnabled;
                }, function() {
                    ngModel.$parsers.italianVatCode(ngModel.$viewValue);
                    ngModel.$validate();
                });

                var hasFocus = false;

                element.on('blur', function() {
                    hasFocus = false;
                    ngModel.$parsers.italianVatCode(ngModel.$viewValue);
                });
                element.on('focus', function() {
                    hasFocus = true;
                });

                ngModel.$parsers.italianVatCode = function(value) {
                    if (isEnabled && !hasFocus && value) {
                        value = ItalianCodeService.vatCodeParser(value);
                        ngModel.$setViewValue(value);
                        ngModel.$commitViewValue();
                        ngModel.$render();
                    }
                    return value;
                };

                ngModel.$validators.italianVatCode = function(modelValue) {
                    if (!isEnabled
                        || modelValue === undefined
                        || modelValue === null
                        || modelValue === ''
                    ) {
                        return true;
                    }

                    return ItalianCodeService.vatCodeValidation(modelValue);
                }
            }
        };
    }])

    .directive('italianVatCodeWithNotLeadingEightOrNine', ['ItalianCodeService', function(ItalianCodeService) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {
                var isEnabled = true;

                scope.$watch(function() {
                    isEnabled = scope.$eval(attr.italianVatCodeWithNotLeadingEightOrNine);
                    return isEnabled;
                }, function() {
                    ngModel.$parsers.italianVatCodeWithNotLeadingEightOrNine(ngModel.$viewValue);
                    ngModel.$validate();
                });

                var hasFocus = false;

                element.on('blur', function() {
                    hasFocus = false;
                    ngModel.$parsers.italianVatCodeWithNotLeadingEightOrNine(ngModel.$viewValue);
                });
                element.on('focus', function() {
                    hasFocus = true;
                });

                ngModel.$parsers.italianVatCodeWithNotLeadingEightOrNine = function(value) {
                    if (isEnabled && !hasFocus && value) {
                        value = ItalianCodeService.vatCodeParser(value);
                        ngModel.$setViewValue(value);
                        ngModel.$commitViewValue();
                        ngModel.$render();
                    }
                    return value;
                };

                ngModel.$validators.italianVatCodeWithNotLeadingEightOrNine = function(modelValue) {
                    if (!isEnabled
                        || modelValue === undefined
                        || modelValue === null
                        || modelValue === ''
                    ) {
                        return true;
                    }

                    return ItalianCodeService.vatCodeValidation(modelValue) && /^(?![89])[0-9]+$/.test(modelValue);
                }
            }
        };
    }])

    /***********************************************************
     * String
     ***********************************************************/
    .directive('toUpperCase', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {
                var hasFocus = false;

                element.on('blur', function () {
                    hasFocus = false;
                    ngModel.$parsers.toUpperCase(ngModel.$viewValue);
                });

                element.on('focus', function () {
                    hasFocus = true;
                });

                ngModel.$parsers.toUpperCase = function (value) {
                    if (!hasFocus && value) {
                        ngModel.$setViewValue(value.toUpperCase());
                        ngModel.$commitViewValue();
                        ngModel.$render();
                    }
                    return value;
                };
            }
        };
    })

    .directive('toLowerCase', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {

                var hasFocus = false;

                element.on('blur', function () {
                    hasFocus = false;
                    ngModel.$parsers.toLowerCase(ngModel.$viewValue);
                });

                element.on('focus', function () {
                    hasFocus = true;
                });

                ngModel.$parsers.toLowerCase = function (value) {
                    if (!hasFocus && value) {
                        ngModel.$setViewValue(value.toLowerCase());
                        ngModel.$commitViewValue();
                        ngModel.$render();
                    }
                    return value;
                };
            }
        };
    })

    .directive('noSpaces', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {

                var hasFocus = false;

                element.on('blur', function () {
                    hasFocus = false;
                    ngModel.$parsers.noSpaces(ngModel.$viewValue);
                });

                element.on('focus', function () {
                    hasFocus = true;
                });

                ngModel.$parsers.noSpaces = function (value) {
                    if (!hasFocus && value) {
                        ngModel.$setViewValue(value.replace(/\s/g, ''));
                        ngModel.$commitViewValue();
                        ngModel.$render();
                    }
                    return value;
                };
            }
        };
    })

    /***********************************************************
     * Technical
     ***********************************************************/
    .directive('macAddress', function(){
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {

                ngModel.$validators.macAddress = function(modelValue) {

                    if (modelValue === undefined
                        || modelValue === null
                        || modelValue === ''
                    ) {
                        return true;
                    }

                    return modelValue.match(/^(([A-Fa-f0-9]{2}[:]){5}([A-Fa-f0-9]{2}))+$/) !== null;
                }
            }
        };
    });
