'use strict';

angular.module('forms.modules.forms.virtual-bundle',
    [
        'forms.modules.forms.bundle-items',
        'forms.modules.forms.bundle-price',
        'rol.form',
        'rol.translation'
    ]
)

    .directive('virtualBundleForm', function() {
        return {
            restrict: 'E',
            scope: {
                'definition': '=',
                'parameters': '=',
                'data': '=',
                'form': '=',
                'bundleInformation': '=',

                'hasValidDependencies': '=',
                'prepareData': '='
            },
            templateUrl: 'modules/forms/virtual-bundle-form/virtual-bundle-form.tpl.html',

            link: function(scope) {
                scope.virtualBundleItemOptions = {};

                /*****************************************
                 * Distinction
                 *****************************************/
                scope.isOptional = function(virtualBundleItem) {
                    return (virtualBundleItem['required'] === false);
                };

                scope.isSingle = function(virtualBundleItem) {
                    return (virtualBundleItem['items'].length === 1);
                };

                scope.isChoice = function(virtualBundleItem) {
                    return (virtualBundleItem['items'].length !== 1);
                };

                scope.isOptionalSingle = function(virtualBundleItem) {
                    return scope.isOptional(virtualBundleItem) && scope.isSingle(virtualBundleItem);
                };

                scope.isOptionalChoice = function(virtualBundleItem) {
                    return scope.isOptional(virtualBundleItem) && scope.isChoice(virtualBundleItem);
                };

                /*****************************************
                 * Definition
                 *****************************************/
                scope.getBundleDefinition = function(virtualBundleItem) {
                    var bundleData = scope.getBundleData(virtualBundleItem);
                    
                    var id = bundleData['object_id'];

                    for (var i = 0; i < virtualBundleItem['items'].length; i++) {
                        if (virtualBundleItem['items'][i]['bundle']['id'] === id) {
                            return virtualBundleItem['items'][i]['bundle'];
                        }
                    }

                    return null;
                };

                scope.getBundleData = function(virtualBundleItem) {
                    return scope.data['items'][virtualBundleItem.id]['bundle'];
                };

                /*****************************************
                 * Selections
                 *****************************************/
                scope.changeSingleSelection = function(virtualBundleItem, bundle) {
                    if (scope.isSelected(virtualBundleItem, bundle)) {
                        scope.data['items'][virtualBundleItem.id]['bundle'] = null;
                    } else {
                        scope.data['items'][virtualBundleItem.id]['bundle'] = {
                            'object_id': bundle['id'],
                            'object_type': 'bundle'
                        };
                    }

                    scope.prepareData();
                };

                scope.changeChoiceSelection = function(virtualBundleItem, bundle) {
                    if (bundle === null) {
                        scope.data['items'][virtualBundleItem.id]['bundle'] = null;
                    } else {
                        scope.data['items'][virtualBundleItem.id]['bundle'] = {
                            'object_id': bundle['id'],
                            'object_type': 'bundle'
                        };
                    }

                    scope.prepareData();
                };

                scope.isSelected = function(virtualBundleItem, bundle) {
                    var bundleData = scope.getBundleData(virtualBundleItem);

                    if (bundleData === null && (bundle === null || bundle === undefined)) {
                        return true;
                    }

                    if (bundleData === null || (bundle === null || bundle === undefined)) {
                        return false;
                    }

                    return (bundleData['object_id'] === bundle['id']);
                };

                scope.hasImage = function(virtualBundleItem) {
                    var hasImage = false;
                    for (var itemsIndex = 0; itemsIndex < virtualBundleItem.items.length; ++itemsIndex) {
                        var item = virtualBundleItem.items[itemsIndex];
                        if (typeof item.bundle.article !== 'undefined') {
                            if (item.bundle.article.image_url_small !== null) {
                                hasImage = true;
                            }
                        }
                    }
                    return hasImage;
                };

                scope.hasBundleActivationArticle = function(bundle) {
                    return bundle.activation_article;
                };

                scope.hasBundleDeactivationArticle = function(bundle) {
                    return bundle.deactivation_article;
                };

                scope.hasBundleDetails = function(bundle) {
                    for(var i = 0; i < bundle['bundle_items'].length; i++) {
                        var bundleItem = bundle['bundle_items'][i];

                        if (bundleItem['visible'] === true) {
                            return true;
                        }

                        if (bundleItem['form'] !== null && bundleItem['form'] !== undefined) {
                            return true;
                        }
                    }

                    return false;
                };
            }
        };
    });
