'use strict';

angular.module('forms.components.customer-import',
    [
        'rol.translation'
    ]
)

    .directive('customerImport', function($timeout) {
        return {
            restrict: 'E',
            scope: {
                outerApplyCustomer: '=applyCustomer',
                customersService: '='
            },
            templateUrl: 'components/customer-import/customer-import.tpl.html',
            link: function(scope) {

                scope.searchTextLoading = false;

                scope.$watch('searchText', function (searchText) {
                    if (typeof searchText === 'string' && searchText != '') {
                        scope.searchTextLoading = true;
                        scope.customersService.search(searchText, function(searchResults) {
                            scope.searchResults = searchResults;
                            scope.searchTextLoading = false;
                        });
                    } else {
                        scope.searchResults = [];
                    }
                });

                scope.applyCustomer = function(customer) {
                    scope.outerApplyCustomer(customer);

                    if (scope.$parent && scope.$parent.$parent && typeof scope.$parent.$parent.closeModal == 'function') {
                        scope.$parent.$parent.closeModal();
                    }

                    // Wait for the modal to close before clearing
                    $timeout(function() {
                        scope.searchText = '';
                    }, 500)

                };
            }
        };
    });