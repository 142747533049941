'use strict';

angular.module('forms.modules.sepa',
    [
        'forms.helper',
        'rol.form.validation',
        'rol.translation'
    ]
)

    .directive('sepa', function() {
        return {
            restrict: 'E',
            scope: {
                'actions': '=',
                'parameters': '=',
                'info': '=',
                'data': '='
            },
            templateUrl: 'modules/sepa/sepa.tpl.html',
            link: function(scope) {

                /*****************************************
                 * Defaults
                 *****************************************/
                // Get default invoice email and invoice type from customer billing or customer
                var iban = undefined;
                var customerInformation = scope.data.customer_billing_information || scope.data.customer_information;

                if (customerInformation) {
                    iban = customerInformation.iban || iban;
                }

                var defaults = {
                    payment_mode: 'recurring',
                    iban: iban
                };

                /*****************************************
                 * Validation
                 *****************************************/
                scope.hasValidationErrors = function() {
                    return scope.sepaForm.$submitted && !scope.sepaForm.$valid;
                };

                scope.getValidationClass = function(field) {
                    if(scope['sepaForm'].$submitted && scope['sepaForm'][field].$invalid) {
                        return 'forms__label--error';
                    }

                    return '';
                };

                /*****************************************
                 * Actions
                 *****************************************/
                scope.actions.validate = function () {
                    scope.sepaForm.$setSubmitted();

                    return (scope.sepaForm.$valid === true);
                };

                /*****************************************
                 * Init
                 *****************************************/
                function init() {
                    scope.data.sepa = scope.data.sepa || angular.copy(defaults);
                }

                init();
            }
        };
    })

    .service('SepaSummaryService', function(Translator) {

        return {
            getSummary: function(info, data, parameters) {

                var paymentModeValues = {
                    'single': Translator.translate('forms.sepa.payment_mode-single'),
                    'recurring': Translator.translate('forms.sepa.payment_mode-recurring')
                };

                return [
                    {
                        'section': Translator.translate('forms.sepa.sepa'),
                        'data': [
                            {
                                'identifier': 'sepa.iban',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'sepa.swift',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'sepa.bank',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'sepa.office',
                                'type': 'key-value'
                            },
                            {
                                'identifier': 'sepa.payment_mode',
                                'type': 'key-value',
                                'value': paymentModeValues[data.sepa.payment_mode]
                            }
                        ]
                    }
                ];
            }
        };
    });