'use strict';

angular.module('forms.services.contracts',
    [
        'forms.config',
        'forms.env',
        'rol.api'
    ]
    )

    .service('ContractsService', function (env, ApiService, $http) {
        
        function getMethod(id) {
            return (id === null) ? 'POST' : 'PUT';
        }
        
        function getUrl(id) {
            return env['apiUrl'] + 'contracts' + ((id === null) ? '' : '/' + id);
        }
        
        
        function get(id, successCallback, failureCallback) {
            $http({
                method: 'GET',
                url: env['apiUrl'] + 'contracts/' + id
            }).then(ApiService.successMiddleware(successCallback), ApiService.failureMiddleware(failureCallback));
        }
        
        function save(id, data, successCallback, failureCallback) {
            $http({
                method: getMethod(id),
                url: getUrl(id) + '?draft=true',
                data: data
            }).then(ApiService.successMiddleware(successCallback), ApiService.failureMiddleware(failureCallback));
        }


        function complete(id, data, successCallback, failureCallback) {
            $http({
                method: getMethod(id),
                url: getUrl(id),
                data: data
            }).then(ApiService.successMiddleware(successCallback), ApiService.failureMiddleware(failureCallback));
        }
        
        return {
            get: get,
            save: save,
            complete: complete
        };
    });