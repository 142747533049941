'use strict';

angular.module('forms.services.customers',
    [
        'forms.config',
        'forms.env',
        'rol.api'
    ]
)

    .service('FormsCustomersService', function (env, ApiService, $http) {

        function search(search, successCallback, failureCallback) {
            $http({
                method: 'GET',
                url: env['apiUrl'] + 'customers/forms',
                params: {
                    search: search
                }
            }).then(ApiService.successMiddleware(successCallback), ApiService.failureMiddleware(failureCallback));
        }
        
        return {
            search: search
        };
    })

    .service('RolCustomersService', function (env, ApiService, $http) {

        function search(search, successCallback, failureCallback) {
            $http({
                method: 'GET',
                url: env['apiUrl'] + 'customers/rol',
                params: {
                    search: search
                }
            }).then(ApiService.successMiddleware(successCallback), ApiService.failureMiddleware(failureCallback));
        }

        return {
            search: search
        };
    });